import React from 'react';

import WebHeader from '../Components/Web/Header';
import WebNavigation from '../Components/Web/Navigation';
import WebMain from '../Components/Web/Main';
import WebWhitepaper from '../Components/Web/Whitepaper';
import WebAbout from '../Components/Web/About';
import WebMobileApp from '../Components/Web/MobileApp';
import WebGroup from '../Components/Web/Group';
import WebPartners from '../Components/Web/Partners';
import WebPress from '../Components/Web/Press';
import WebRoadmap from '../Components/Web/Roadmap';
import WebContact from '../Components/Web/Contact';
import WebFooter from '../Components/Web/Footer';

function WebView({ lang, changeLanguage }) {
  return (
    <div>
      <WebHeader changeLanguage={changeLanguage} />
      <WebNavigation />
      <WebMain lang={lang} />
      <WebWhitepaper lang={lang} />
      <WebAbout lang={lang} />
      <WebMobileApp lang={lang} />
      <WebGroup lang={lang} />
      <WebPartners lang={lang} />
      <WebPress lang={lang} />
      <WebRoadmap lang={lang} />
      <WebContact lang={lang} />
      <WebFooter />
    </div>
  );
}

export default WebView;
