import React from 'react';
import styled from 'styled-components';

import Link from '../../meta/Contact';
import { contact } from '../common/images';
import { OuterWrapper } from './Main';

const {
  medium, twitter, naver, telegramGlobal, telegramKorean, telegramAnnouncement,
} = Link;

const {
  mediumIcon, naverIcon, telegramIcon, twitterIcon,
} = contact;

const Container = styled.div`
  width: 100%;
  height: 420px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
const Title = styled.div`
  margin-bottom: 24px;

  font-size: 30px;
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: -0.94px;
  text-align: center;
`;
const ContactDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  margin-bottom: 24px;
`;
const EmailDiv = styled.div`
  margin-top: 8px;

  font-size: ${({ phoneSize }) => ( phoneSize !== 'xs' ? '14px' : '12px' )};
  text-align: center;

  .email-text {
    color: #4874f6;
    font-weight: bold;
    line-height: 1.43;
  }
  .bottom-margin {
    margin-bottom: 8px;
  }
`;

function Contact({ phoneSize }) {
  const moveSocialLink = ( path ) => window.open( path );

  return (
    <Container name="contact">
      <OuterWrapper phoneSize={phoneSize}>
        <Title><span>Contact</span></Title>
        <ContactDiv>
          <ContactBox name="Medium" icon={mediumIcon} phoneSize={phoneSize} onClick={() => moveSocialLink( medium )} />
          <ContactBox name="X" icon={twitterIcon} phoneSize={phoneSize} onClick={() => moveSocialLink( twitter )} />
          <ContactBox name="Naver" icon={naverIcon} phoneSize={phoneSize} onClick={() => moveSocialLink( naver )} />
          <ContactBox name="Global Community" icon={telegramIcon} phoneSize={phoneSize} onClick={() => moveSocialLink( telegramGlobal )} />
          <ContactBox name="Korean Community" icon={telegramIcon} phoneSize={phoneSize} onClick={() => moveSocialLink( telegramKorean )} />
          <ContactBox name="Announcement" icon={telegramIcon} phoneSize={phoneSize} onClick={() => moveSocialLink( telegramAnnouncement )} />
        </ContactDiv>
        <EmailDiv phoneSize={phoneSize}>
          <div className="bottom-margin" />
          <div><span className="email-text">hello@apm-coin.com</span></div>
        </EmailDiv>
      </OuterWrapper>
    </Container>
  );
}

const ContactBoxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 16px;
  height: 24px;

  .text-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 8px;
    width: ${({ phoneSize }) => {
    switch ( phoneSize ) {
    case 'xs': return '104px';
    case 's': return '120px';
    default: return '136px';
    }
  }};
    height: 100%;
    background-color: #272f3a;
    font-size: 10px;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: -0.63px;
  }
  .icon-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 100%;
    background-color: #000;
  }
`;
function ContactBox({
  name, icon, phoneSize, onClick,
}) {
  return (
    <ContactBoxContainer phoneSize={phoneSize} onClick={onClick}>
      <div className="text-box"><span>{name}</span></div>
      <div className="icon-box">
        <img src={icon} alt="icon" style={{ width: '18px' }} />
      </div>
    </ContactBoxContainer>
  );
}

export default Contact;
