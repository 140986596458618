import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';

import { header } from '../common/images';

const Container = styled.section`
  min-width: 1250px;
  overflow: hidden;
`;

const Wrapper = styled.div`
  width: 1050px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
`;

const LowerBrackground = styled.div`
  background-color: #000;
`;

const LowerWrapper = styled( Wrapper )`
  height: 96px;
  font-family: GmarketSans;
`;

const Logo = styled.img`
  width: 186px;
  height: 40px;
`;

const List = styled.ul`
  display: flex;
`;

const Item = styled.li`
  margin-right: 40px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.57;
  letter-spacing: -0.44px;
  color: 
    ${( props ) => ( props.current ? '#4874f6' : '#fffffff' )};
  transition: all 0.5s ease-in-out;
  &:hover, &:active {
    color: #4874f6;
  }
  &:last-child {
    margin: 0;
  }
  cursor: pointer;
`;

function Navigation() {
  return (
    <Container>
      <LowerBrackground>
        <LowerWrapper>
          <Logo src={header.logoImg} alt="apM coin logo" />
          <List>
            <Item>
              <Link to="about" spy smooth duration={500}>apM Coin</Link>
            </Item>
            <Item>
              <Link to="mobileApp" spy smooth duration={500}>apM Members</Link>
            </Item>
            <Item>
              <Link to="group" spy smooth duration={500}>apM Group</Link>
            </Item>
            <Item>
              <Link to="contact" spy smooth duration={500}>Contact</Link>
            </Item>
          </List>
        </LowerWrapper>
      </LowerBrackground>
    </Container>
  );
}

export default Navigation;
