import React from 'react';
import styled from 'styled-components';

const Container = styled.section`
  min-width: 1250px;
  overflow: hidden;
  color: #969696;
  background-color: #141414;
  font-family: GmarketSans;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 40px;
  margin: 0 auto;
  max-width: 1050px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

function Footer() {
  return (
    <Container>
      <Wrapper>
        © DA Network Pte. Ltd., Since 2019. All Rights Reserved.
      </Wrapper>
    </Container>
  );
}

export default Footer;
