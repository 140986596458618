export const group = {
  ko: [
    { id: 0, text: 'apM 그룹은 대한민국 도매 패션 시장을 대표하는 기업입니다. 1999년 설립된 apM을 시작으로 apM Luxe와 apM PLACE까지 총 세 개의 패션 도매 쇼핑몰을 운영하고 있는 동대문 최대 쇼핑몰로 한국 패션 도매 시장 매출의 30%를 점유하고 있습니다.' },
    { id: 1, text: '또한 apM 그룹의 쇼핑몰에서는 자체 디자이너를 보유한 1,000여 개의 도매상들이 우수한 품질의 최신 유행 패션 상품을 선보이고 있습니다.\n일일 평균 최대 10,000여 명, 연간 누적 150만 명 이상의 방문자가 apM 쇼핑몰을 찾고 있습니다. 방문자의 80%가 중국인으로 K 패션의 글로벌 진출 통로로서 역할을 하고 있습니다.' },
  ],
  en: [
    { id: 2, text: 'apM Group is the leading company of the Korean wholesale fashion industry. Since its establishment in 1999 with the apM shopping mall, the company has expanded its business managing three wholesale fashion shopping malls – apM, apM Luxe and apM PLACE; about 30% of sales for the Korean wholesale fashion market takes place in the malls.' },
    { id: 3, text: '1,000+ wholesalers and their own designers have presented premium-quality and latest fashion clothing and accessories. Also, more than 10,000 customers visit the apM malls daily on average; the number of annual visitors has totaled more than 1.5 million. apM Group has contributed to K-fashion’s global expansion as our malls attract global buyers and tourists, especially Chinese buyers who take about 80% of the visitors.' },
  ],
};

export const globalMarket = {
  ko: [
    { id: 0, text: 'apM 그룹은 2023년 8월, 글로벌 최대의 의류 도매시장이 위치한 중국 광저우에 apM 몰을 오픈할 예정이며, 중국시장을 넘어 글로벌의류시장까지 유통 영역을 확대해 나갈 예정입니다.' },
  ],
  en: [
    { id: 1, text: 'August 2023, apM group will open \'apM mall\' in Guangzhou, China, where the world\'s largest clothing wholesale market is located, and will expand its distribution beyond the Chinese market to the global clothing market.' },
  ],
};

export const count = {
  ko: [
    { id: 0, value: '1,000+', title: '입점 브랜드' },
    { id: 1, value: '10,000+', title: '일 평균 방문자 수' },
    {
      id: 2, value: '1.5', unit: 'Million', title: '연간 누적 방문자 수',
    },
    { id: 3, value: '80%', title: '중국 및 해외고객 비율' },
  ],
  en: [
    { id: 4, value: '1,000+', title: 'Wholesale brands' },
    { id: 5, value: '10,000+', title: 'Average daily visitors' },
    {
      id: 6, value: '1.5', unit: 'Million', title: 'Cumulative annual visitors',
    },
    { id: 7, value: '80%', title: 'Global wholesale shoppers(China, etc)' },
  ],
};
