import React from 'react';
import styled from 'styled-components';

import Link from '../../meta/Header';
import { OuterWrapper } from './Main';
import { header } from '../common/images';

const {
  github, medium, twitter, telegram,
} = header;

const Container = styled.header`
  width: 100%;
  height: 48px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: GmarketSans;
  background-color: #1e1e1e;
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LanguageWrapper = styled.ul`
  display: flex;
  align-items: center;
  font-size: 14px;
`;

const Language = styled.li`
  margin: 0 24px 0 0;
  cursor: pointer;
  &:hover {
    font-weight: bold;
  }
`;

const Socials = styled.ul`
  display: flex;
  float: right;
`;

const Social = styled.li`
  margin: 0 20px 0 0;
  cursor: pointer;
  &:last-child {
    margin: 0;
  }
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function Header({ tabletSize, changeLanguage }) {
  const moveSocialLink = ( path ) => window.open( path );

  return (
    <Container>
      <OuterWrapper tabletSize={tabletSize}>
        <InnerWrapper>
          <LanguageWrapper>
            <Language onClick={() => changeLanguage( 'ko' )}>KO</Language>
            <Language onClick={() => changeLanguage( 'en' )}>EN</Language>
          </LanguageWrapper>
          <Socials>
            <Social onClick={() => moveSocialLink( Link.github )}>
              <Icon src={github} alt="github" />
            </Social>
            <Social onClick={() => moveSocialLink( Link.medium )}>
              <Icon src={medium} alt="medium" />
            </Social>
            <Social onClick={() => moveSocialLink( Link.twitter )}>
              <Icon src={twitter} alt="twitter" />
            </Social>
            <Social onClick={() => moveSocialLink( Link.telegram )}>
              <Icon src={telegram} alt="telegram" />
            </Social>
          </Socials>
        </InnerWrapper>
      </OuterWrapper>
    </Container>
  );
}

export default Header;
