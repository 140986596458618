// header
import logoImg from '../../assets/Header/logo-nav-white/logo-nav-white@3x.png';
import github from '../../assets/Header/rsc-icon-github-s_2023-12-19/rsc-icon-github-s@3x.png';
import medium from '../../assets/Header/rsc-icon-medium-s_2023-12-19/rsc-icon-medium-s@3x.png';
import twitter from '../../assets/Header/rsc-icon-x-s_2023-12-19/rsc-icon-x-s@3x.png';
import telegram from '../../assets/Header/rsc-icon-telegram-s/rsc-icon-telegram-s@3x.png';

// main
import blockchainIcon from '../../assets/Main/rsc-img-blockchain-w/rsc-img-blockchain-w@3x.png';
import downloadIcon from '../../assets/Main/rsc-icon-download/rsc-icon-download@3x.png';
import mainBackground from '../../assets/Main/img-bg-apmplace-01/img-bg-apmplace-01@3x.png';

// whitepaper
import whitepaperDownloadIcon from '../../assets/Whitepaper/rsc-icon-download/rsc-icon-download@3x.png';

// about
import aboutBackground from '../../assets/About/img-bg-blockchain-01/img-bg-blockchain-01@3x.png';
import aboutDiagram from '../../assets/About/diagram-mb/diagram-mb-edit@3x.png';
import aboutDiagramWeb from '../../assets/About/diagram-web/diagram-pc-edit@3x.png';
import evoucherIcon from '../../assets/About/rsc-img-evoucher/rsc-img-evoucher@3x.png';
import blockchainIcon2 from '../../assets/About/rsc-img-blockchain-b/rsc-img-blockchain-b@3x.png';
import nextIcon from '../../assets/About/img-icon-next/img-icon-next@3x.png';

// mobile app
import phonemockup from '../../assets/MobileApp/bg-web/img-bg-phonemockup-main/img-bg-phonemockup-main@3x.png';
import appIcon from '../../assets/MobileApp/rsc-img-appicon/rsc-img-appicon@3x.png';

// group
import groupBackgroundMobile from '../../assets/Group/img-bg-apmplace-02-mobile/img-bg-apmplace-02.png';

// partner
import apM from '../../assets/Partners/partners-apm-w/partners-apm-w@3x.png';
import apMLuxe from '../../assets/Partners/partners-apmluxe-w/partners-apmluxe-w@3x.png';
import apMPlace from '../../assets/Partners/partners-apmplace-w/partners-apmplace-w@3x.png';
import seum from '../../assets/Partners/partners-seum-w/partners-seum-w@3x.png';
import jenga from '../../assets/Partners/partners-jenga-w/partners-jenga-w@3x.png';
import megazone from '../../assets/Partners/partners-megazone-w/partners-megazone-w@3x.png';
import certik from '../../assets/Partners/partners-certik-w/partners-certik-w@3x.png';
import sentbe from '../../assets/Partners/partners-sentbe-w/partners-sentbe-w@3x.png';
import chainlink from '../../assets/Partners/partners-chainlink-w/partners-chainlink-w@3x.png';
import tomo from '../../assets/Partners/partners-tomo-w/partners-tomo-w@3x.png';
import polynetwork from '../../assets/Partners/partners-polynetwork-w/partners-polynetwork-w@3x.png';
import alphaquark from '../../assets/Partners/partners-alphaquark-w/partners-alphaquark-w@3x.png';
import teammates from '../../assets/Partners/partners-teammates-w/partners-teammates-w@3x.png';
import xangle from '../../assets/Partners/partners-xangle-w/partners-xangle-w@3x.png';
import dsc from '../../assets/Partners/partners-dsc-w/partners-dsc-w@3x.png';
import gaia from '../../assets/Partners/partners-gaia-w/partners-gaia-w@3x.png';
import klubs from '../../assets/Partners/partners-klubs-w/partners-klubs-w@3x.png';
import ozys from '../../assets/Partners/partners-ozys-w/partners-ozys-w@3x.png';
import megaton from '../../assets/Partners/partners-megaton-w/partners-megaton-w@3x.png';
import dwf from '../../assets/Partners/partners-dwf/partners-dwf@3x.png';
import googleCloud from '../../assets/Partners/partners-google-w/partners-google-w@3x.png';
import aws from '../../assets/Partners/partners-aws-w/partners-aws-w@3x.png';

// press
import outlinkIcon from '../../assets/Press/rsc-icon-outlink/rsc-icon-outlink@3x.png';

// contact
import mediumIcon from '../../assets/Contact/rsc-icon-medium_2023-12-19/rsc-icon-medium@3x.png';
import naverIcon from '../../assets/Contact/rsc-icon-naver/rsc-icon-naver@3x.png';
import telegramIcon from '../../assets/Contact/rsc-icon-telegram/rsc-icon-telegram@3x.png';
import twitterIcon from '../../assets/Contact/rsc-icon-x_2023-12-19/rsc-icon-x@3x.png';

export const header = {
  logoImg, github, medium, twitter, telegram,
};

export const main = {
  blockchainIcon, downloadIcon, mainBackground,
};

export const whitepaper = {
  whitepaperDownloadIcon,
};

export const about = {
  aboutBackground, aboutDiagram, aboutDiagramWeb, evoucherIcon, blockchainIcon2, nextIcon,
};

export const mobileApp = {
  phonemockup, appIcon, nextIcon,
};

export const group = {
  groupBackgroundMobile,
};

export const partners = [
  {
    name: 'apM', src: apM, style: { height: '45px' }, mStyle: { height: '28px' },
  },
  {
    name: 'apMLuxe', src: apMLuxe, style: { height: '45px' }, mStyle: { height: '28px' },
  },
  {
    name: 'apMPlace', src: apMPlace, style: { height: '45px' }, mStyle: { height: '28px' },
  },
  {
    name: 'seum', src: seum, style: { height: '70px' }, mStyle: { height: '44px' }, tStyle: { height: '50px' },
  },
  {
    name: 'jenga', src: jenga, style: { height: '70px' }, mStyle: { height: '44px' }, tStyle: { height: '50px' },
  },
  {
    name: 'megazone', src: megazone, style: { height: '55px', marginRight: '3px' }, mStyle: { height: '35px' }, tStyle: { height: '42px' },
  },
  {
    name: 'certik', src: certik, style: { height: '52px' }, mStyle: { height: '33px' },
  },
  {
    name: 'sentbe', src: sentbe, style: { height: '43px' }, mStyle: { height: '27px' },
  },
  {
    name: 'chainlink', src: chainlink, style: { height: '55px' }, mStyle: { height: '35px' }, tStyle: { height: '45px' },
  },
  {
    name: 'tomo', src: tomo, style: { height: '50px' }, mStyle: { height: '32px' }, tStyle: { height: '40px' },
  },
  {
    name: 'polynetwork', src: polynetwork, style: { height: '65px' }, mStyle: { height: '42px' }, tStyle: { height: '50px' },
  },
  {
    name: 'alphaquark', src: alphaquark, style: { height: '58px' }, mStyle: { height: '37px' }, tStyle: { height: '43px' },
  },
  {
    name: 'teammates', src: teammates, style: { height: '33px', marginLeft: '5px' }, mStyle: { height: '21px' }, tStyle: { height: '27px' },
  },
  { name: 'xangle', src: xangle, mStyle: { height: '25px' } },
  {
    name: 'dsc', src: dsc, style: { height: '37px' }, mStyle: { height: '24px' }, tStyle: { height: '30px' },
  },
  {
    name: 'gaia', src: gaia, style: { height: '50px' }, mStyle: { height: '32px' }, tStyle: { height: '40px' },
  },
  // {
  //   name: 'klubs', src: klubs, style: { height: '35px' }, mStyle: { height: '22px' },
  // },
  {
    name: 'ozys', src: ozys, style: { height: '50px', marginTop: '10px' }, mStyle: { height: '32px' },
  },
  {
    name: 'megaton', src: megaton, style: { height: '43px' }, mStyle: { height: '30px' },
  },
  {
    name: 'dwf', src: dwf, style: { height: '35px' }, mStyle: { height: '22px' }, tStyle: { height: '30px' },
  },
  {
    name: 'googleCloud', src: googleCloud, style: { height: '47px' }, mStyle: { height: '29px' }, tStyle: { height: '30px' },
  },
  {
    name: 'aws', src: aws, style: { height: '40px' }, mStyle: { height: '26px' }, tStyle: { height: '30px' },
  },
  {
    name: 'created by kimms', src: '', style: { height: '35px', visibility: 'hidden' }, mStyle: { visibility: 'hidden' },
  },
  {
    name: 'created by leehs', src: '', style: { height: '35px', visibility: 'hidden' }, mStyle: { visibility: 'hidden' },
  },
  {
    name: 'created by jeongsw', src: '', style: { height: '35px', visibility: 'hidden' }, mStyle: { visibility: 'hidden' },
  },
];

export const press = {
  outlinkIcon,
};

export const contact = {
  mediumIcon, naverIcon, telegramIcon, twitterIcon,
};
