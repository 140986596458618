import React from 'react';
import styled from 'styled-components';

import { group, globalMarket, count } from '../../meta/Group';
import { OuterWrapper } from './Main';

const bg = require( '../../assets/Group/bg-web/img-bg-apmplace-02/img-bg-apmplace-02@3x.png' );

const Container = styled.section`
  width: 100%;
  height: 920px;
  padding-bottom: 175px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-image: linear-gradient( rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8) ),url(${( props ) => props.bgImage});
  background-position: center center;
  background-size: cover;

  position: relative;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: GmarketSans;
  font-size: 56px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -1.75px;
  color: #4874f6;
`;

const AboutGroupWrapper = styled.div`
  width: 100%;
  margin-top: ${({ marginTop }) => ( marginTop )};

  .about-group-title {
    font-family: GmarketSans;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.75px;
  }
  .about-group-detail {
    display: flex;
    justify-content: space-between;
    font-family: AppleSDGothicNeo;
  }
`;

const AboutGroup = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const GroupIntroduction = styled.div`
  .group-introduction-detail {
    width: 360px;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: -0.88px;
    word-break: ${( props ) => ( props.lang === 'ch' ? 'break-all' : 'keep-all' )};
    white-space: pre-wrap;
  }
`;

const CountGroup = styled.div`
  width: 100%;
  height: 175px;
  background-color: #19202a;
  position: absolute;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;
const Count = styled.div`
  margin: 0 30px;
  .count-value {
    font-family: GmarketSans;
    font-size: 34px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -1.75px;
    text-align: center;
    color: #4874f6;
  }
  .count-unit {
    font-family: GmarketSans;
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.94px;
    color: #4874f6;
    margin-top: 25px;
  }
  .count-title {
    font-size: ${( props ) => ( props.lang === 'en' ? '12px' : '17px' )};
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: -0.75px;
    text-align: center;
    color: #969696;
    white-space: pre-wrap;
  }
`;
const CountUnitContainer = styled.div`
  display: flex;
  justify-content: center;
`;

function Group({ lang, tabletSize }) {
  return (
    <Container name="group" bgImage={bg}>
      <OuterWrapper tabletSize={tabletSize}>
        <Title>
          apM Group:
          <br />
          The Partner of apM Coin
        </Title>

        <AboutGroupWrapper marginTop="80px">
          <AboutGroup>
            <div className="about-group-title">
              apM Group
            </div>
            <div className="about-group-detail">
              {group[lang].map( ( el ) => (
                <GroupIntroduction lang={lang} key={el.id}>
                  <div className="group-introduction-detail">{el.text}</div>
                </GroupIntroduction>
              ) )}
            </div>
          </AboutGroup>
        </AboutGroupWrapper>

        <AboutGroupWrapper marginTop="22px">
          <AboutGroup>
            <div className="about-group-title">
              apM Group in Global Market
            </div>
            <div className="about-group-detail">
              {globalMarket[lang].map( ( el ) => (
                <GroupIntroduction lang={lang} key={el.id}>
                  <div className="group-introduction-detail">{el.text}</div>
                </GroupIntroduction>
              ) )}
            </div>
          </AboutGroup>
        </AboutGroupWrapper>
      </OuterWrapper>

      <CountGroup>
        {count[lang].map( ( el ) => (
          <Count lang={lang} key={el.id}>
            <CountUnitContainer>
              <div className="count-value">{el.value}</div>
              <div className="count-unit">{el.unit}</div>
            </CountUnitContainer>
            <div className="count-title">{el.title}</div>
          </Count>
        ) )}
      </CountGroup>
    </Container>
  );
}

export default Group;
