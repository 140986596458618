import React from 'react';
import { isMobile, isTablet } from 'react-device-detect';

import GlobalStyles from './GlobalStyles';
import DefaultPage from './Page/DefaultPage';

function App() {
  const [ lang, setLang ] = React.useState( 'ko' ); // ko | en

  const changeLanguage = ( language ) => setLang( language );

  const device = { isMobile, isTablet };

  return (
    <div>
      <GlobalStyles />
      <DefaultPage device={device} lang={lang} changeLanguage={changeLanguage} />
    </div>
  );
}

export default App;
