import React from 'react';
import styled from 'styled-components';

import { about } from '../common/images';
import { assetsVoucher, assetsBridge, address } from '../../meta/About';

const bg = require( '../../assets/About/bg-web/img-bg-blockchain-01/img-bg-blockchain-01@3x.png' );

const Container = styled.section`
  min-width: 1250px;
  overflow: hidden;
  height: 1600px;
`;

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 1050px;
`;

const Title = styled( Wrapper )`
  font-family: GmarketSans;
  font-size: 56px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -1.75px;
  .title-white {
    color: #FFFFFF;
  }
  .title-white-sub {
    font-family: GmarketSans;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.75px;
    color: #fff;
    margin-top: 40px;
    margin-bottom: 12px;
  }
`;

const TitleWrapperDarkly = styled.div`
  width: 100%;
  min-width: 1050px;
  height: 1360px;
  background-image: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ),url(${( props ) => props.bgImage});
  background-blend-mode: multiply;
  background-position: center center;
  background-size: cover;
  padding-top: 120px;
`;

const AvailableContainer = styled.div`
  height: 720px;
  margin-top: 60px;
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #141414;
`;

const AvailableTitle = styled.div`
  font-family: GmarketSans;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.75px;
  text-align: center;
  color: #fff;
  margin: 0 0 71px 0;
`;

const AvailableImgContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const AvailableDiagram = styled.img`
  height: 378px;
  margin-top: 40px;
`;

const AboutContainer = styled( Wrapper )`
  display: flex;
  margin-top: 60px;
`;

const AboutContents = styled.div`
  margin-right: 30px;
  .about-contents-title {
    width: 510px;
    height: 36px;
    margin: 0 0 12px;
    font-family: GmarketSans;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.75px;
    color: #fff;
  }
`;

const AboutContentsContainer = styled.div`
  width: 510px;
  height: 240px;
  margin: 12px 0 16px;
  padding-left: 24px;
  background-color: #1e1e1e;
`;

const AboutContentsVoucherImg = styled.img`
  height: 72px;
  margin-top: 24px;
  margin-bottom: 30px;
`;

const AboutContentsApmBridgeImg = styled.img`
  height: 72px;
  margin-top: 24px;
  margin-bottom: 30px;
`;

const AboutContentsDescription = styled.div`
  width: 462px;
  height: 72px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: -0.88px;
  color: #fff;
  word-break: ${( props ) => ( props.lang === 'ch' ? 'break-all' : 'keep-all' )};
`;

const MoreinfoContainer = styled.div`
  width: 462px;
  height: 32px;
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  z-index: 1;
`;

const MoreinfoText = styled.div`
  width: 208px;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 0 0 16px;
  background-color: #4874f6;
  font-family: GmarketSans;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.88px;
  color: #fff;
  cursor: pointer;
`;

const MoreinfoButton = styled.button`
  width: 32px;
  height: 32px;
  padding: 4px;
  background-color: #000;
  cursor: pointer;
  border: none;
`;

const MoreinfoNextButtonImg = styled.img`
  width: 24px;
  height: 24px;
`;

function About({ lang }) {
  const movePage = React.useCallback( ( path ) => {
    window.open( path );
  }, []);

  return (
    <Container name="about">
      <TitleWrapperDarkly bgImage={bg}>
        <Title>
          <div className="title-white">About apM Coin</div>
          <div className="title-white-sub">Securely Managed on apM Chain Platform</div>
        </Title>
        {/* <AvailableContainer>
          <AvailableTitle>
            Availaable on
          </AvailableTitle>
          <AvailableImgContainer>
            <AvailableDiagram src={about.aboutDiagramWeb} alt="diagram" />
          </AvailableImgContainer>
        </AvailableContainer> */}
        <AboutContainer>
          <AboutContents>
            <div className="about-contents-title">apM NFT Voucher</div>
            <AboutContentsContainer>
              <AboutContentsVoucherImg src={about.evoucherIcon} alt="voucher" />
              <AboutContentsDescription lang={lang}>
                {assetsVoucher[lang]}
              </AboutContentsDescription>
            </AboutContentsContainer>
          </AboutContents>
          <AboutContents>
            <div className="about-contents-title">apM Bridge</div>
            <AboutContentsContainer>
              <AboutContentsApmBridgeImg src={about.blockchainIcon2} alt="apM-bridge" />
              <AboutContentsDescription lang={lang}>
                {assetsBridge[lang]}
              </AboutContentsDescription>
              <MoreinfoContainer>
                <MoreinfoText onClick={() => movePage( address.bridge )}>More info</MoreinfoText>
                <MoreinfoButton onClick={() => movePage( address.bridge )}>
                  <MoreinfoNextButtonImg src={about.nextIcon} alt="next" />
                </MoreinfoButton>
              </MoreinfoContainer>
            </AboutContentsContainer>
          </AboutContents>
        </AboutContainer>
      </TitleWrapperDarkly>
    </Container>
  );
}

export default About;
