export const mobileAppDescription = {
  ko: '국내 의류 B2B 시장 최초 도입된 블록체인 기반 고객 리워드 모바일 앱 서비스',
  en: 'Blockchain-powered Customer Rewards Management Platform for the Korea’s Leading Wholesale Fashion Malls',
};

export const mockData = {
  ko: '국내 의류 B2B 시장 최초 도입된 블록체인 기반 고객 리워드 모바일 앱 서비스',
  en: 'Blockchain-powered Customer Rewards Management Platform for the Korea’s Leading Wholesale Fashion Malls',
};

export const address = {
  mobileApp: 'https://apm-members.com',
};
