import React from 'react';
import styled, { css } from 'styled-components';
import { Squash as Menu } from 'hamburger-react';
import { Link } from 'react-scroll';

import { header } from '../common/images';

const { logoImg } = header;

const Container = styled.header`
  background-color: #000000;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: GmarketSans;

  ${( props ) => props.isOpen && css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  `}
`;

const OuterWrapper = styled.div`
  width: 340px;
  padding: ${({ phoneSize }) => {
    switch ( phoneSize ) {
    case 's': return '0 12px';
    case 'xs': return '0 7px';
    default: return '0px';
    }
  }};
  box-sizing: border-box;

  ${( props ) => props.isOpen && css`
    height: 100%;
  `}
`;

const HeaderWrapper = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Logo = styled.img`
  width: 120px;
  height: 28px;
`;

const NavWrapper = styled.div`
  flex-direction: column;
  justify-content: space-between;
  height: calc( 100vh - 60px );

  ${( props ) => ( props.isOpen ? css`
    display: flex;
    transition: opacity 1s ease-in;
    opacity: 1;
  ` : css`
    display: none;
    transition: opacity 1s ease-out;
    opacity: 0;
  ` )}
`;

const Navs = styled.ul`
  margin: 100px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: normal;

  @media ( max-width: 321px ) {
    margin: 50px 0 50px 0;
  }

  @media ( min-width: 600px ) {
    margin: 0;
  }
`;

const Nav = styled.li`
  margin: 36px 0 0 0;

  @media ( min-width: 600px ) {
    margin: 10px 0 0 0;
  }
`;

const LanguageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 0 150px 0;

  @media ( min-width: 600px ) {
    justify-content: flex-start;
    margin: 0 0 10px 0;
  }
`;

const Language = styled.span`
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: -0.63px;
  margin: 0 0 0 24px;
`;

function Header({ menu, phoneSize, changeLanguage }) {
  const { isOpen, changeToggleState, switchOffToggle } = menu;

  return (
    <Container isOpen={isOpen}>
      <OuterWrapper isOpen={isOpen} phoneSize={phoneSize}>
        <HeaderWrapper>
          <Logo src={logoImg} alt="logo" />
          <Menu toggled={isOpen} toggle={changeToggleState} />
        </HeaderWrapper>

        <NavWrapper isOpen={isOpen}>
          <Navs>
            <Nav>
              <Link to="about" spy smooth duration={500} offset={56} onClick={switchOffToggle}>apM Coin</Link>
            </Nav>
            <Nav>
              <Link to="mobileApp" spy smooth duration={500} offset={56} onClick={switchOffToggle}>apM Members</Link>
            </Nav>
            <Nav>
              <Link to="group" spy smooth duration={500} offset={56} onClick={switchOffToggle}>apM Group</Link>
            </Nav>
            <Nav>
              <Link to="contact" spy smooth duration={500} offset={56} onClick={switchOffToggle}>Contact</Link>
            </Nav>
          </Navs>

          <LanguageWrapper isOpen={isOpen}>
            <Language onClick={() => changeLanguage( 'ko' )}>KR</Language>
            <Language onClick={() => changeLanguage( 'en' )}>EN</Language>
          </LanguageWrapper>
        </NavWrapper>
      </OuterWrapper>
    </Container>
  );
}

export default Header;
