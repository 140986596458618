/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import { OuterWrapper } from './Main';
import { group } from '../common/images';
import { count } from '../../meta/Group';

const { groupBackgroundMobile } = group;

const TEXTS = {
  title: 'apM Group:\nThe Partner of\napM Coin',
  apmGroup: 'apM Group',
  apmGroupDesc: {
    ko: 'apM 그룹은 대한민국 도매 패션 시장을 대표하는 기업입니다. 1999년 설립된 apM을 시작으로 apM Luxe와 apM PLACE까지 총 세 개의 패션 도매 쇼핑몰을 운영하고 있는 동대문 최대 쇼핑몰로 한국 패션 도매 시장 매출의 30%를 점유하고 있습니다.\n\n또한 apM 그룹의 쇼핑몰에서는 자체 디자이너를 보유한 1,000여 개의 도매상들이 우수한 품질의 최신 유행 패션 상품을 선보이고 있습니다.​일일 평균 최대 10,000여 명, 연간 누적 150만 명 이상의 방문자가 apM 쇼핑몰을 찾고 있습니다. 방문자의 80%가 중국인으로 K 패션의 글로벌 진출 통로로서 역할을 하고 있습니다.',
    en: 'apM Group is the leading company of the Korean wholesale fashion industry. Since its establishment in 1999 with the apM shopping mall, the company has expanded its business managing three wholesale fashion shopping malls – apM, apM Luxe and apM PLACE; about 30% of sales for the Korean wholesale fashion market takes place in the malls.\n\n1,000+ wholesalers and their own designers have presented premium-quality and latest fashion clothing and accessories. Also, more than 10,000 customers visit the apM malls daily on average; the number of annual visitors has totaled more than 1.5 million. apM Group has contributed to K-fashion’s global expansion as our malls attract global buyers and tourists, especially Chinese buyers who take about 80% of the visitors.',
  },
  visionOfApmGroup: 'Vision of apM Group',
  visionOfApmGroupDesc: {
    ko: 'apM 그룹은 2023년 8월, 글로벌 최대의 의류 도매시장이 위치한 중국 광저우에 apM 몰을 오픈할 예정이며, 중국시장을 넘어 글로벌의류시장까지 유통 영역을 확대해 나갈 예정입니다.',
    en: 'August 2023, apM group will open \'apM mall\' in Guangzhou, China, where the world\'s largest clothing wholesale market is located, and will expand its distribution beyond the Chinese market to the global clothing market.',
  },
};

const Container = styled.section`
  width: 100%;
  height: ${({ lang, phoneSize }) => ( lang === 'ko' ? '990px' : ( phoneSize !== 'xs' ? '1080px' : '1100px' ) )};

  padding-top: 80px;
  padding-bottom: 350px;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  position: relative;

  background-blend-mode: multiply;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)), ${({ imageSrc }) => `url(${imageSrc})`};
  background-size: 100%;
  background-position: center top;
`;
const Title = styled.div`
  font-size: 30px;
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: -0.94px;
  color: #4874f6;
`;
const GroupItemWrapper = styled.div`
  margin-top: 40px;
`;
const CountDivWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: ${({ phoneSize }) => {
    switch ( phoneSize ) {
    case 'xs': return '300px';
    case 's': return '320px';
    default: return '340px';
    }
  }};
  box-sizing: border-box;
  padding: 55px 0 60px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  background-color: #19202a;
`;
const CountDiv = styled.div`
  text-align: center;
  width: ${({ phoneSize }) => {
    switch ( phoneSize ) {
    case 'xs': return '130px';
    case 's': return '140px';
    default: return '164px';
    }
  }};
  height: 84px;

  .number {
    margin-bottom: 8px;

    font-size: ${({ phoneSize }) => {
    switch ( phoneSize ) {
    case 'xs': return '30px';
    case 's': return '35px;';
    default: return '40px';
    }
  }};
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: -1.25px;
    color: #4874f6;
  }
  .million {
    font-size: 20px;
    font-weight: bold;  
    letter-spacing: -0.63px;
  }
  .number-key {
    margin-bottom: 20px;

    font-family: AppleSDGothicNeo;
    font-size: 14px;
    font-weight: bold;
    line-height: 2;
    letter-spacing: -0.44px;
    color: #969696;
  }
`;

function Group({ lang, phoneSize }) {
  return (
    <Container name="group" imageSrc={groupBackgroundMobile} lang={lang} phoneSize={phoneSize}>
      <OuterWrapper phoneSize={phoneSize}>
        <Title><span>{TEXTS.title}</span></Title>

        <GroupItemWrapper>
          <GroupItem itemName={TEXTS.apmGroup} itemDesc={TEXTS.apmGroupDesc[lang]} />
          <GroupItem itemName={TEXTS.visionOfApmGroup} itemDesc={TEXTS.visionOfApmGroupDesc[lang]} />
        </GroupItemWrapper>
      </OuterWrapper>

      <CountDivWrapper phoneSize={phoneSize}>
        {count[lang].map( ( elem ) => (
          <CountDiv key={elem.id} phoneSize={phoneSize}>
            <div className="number">
              {elem.id === 2 ? (
                <div>
                  {elem.value}
                  <span className="million">{elem.unit}</span>
                </div>
              ) : elem.value}
            </div>
            <div className="number-key">{elem.title}</div>
          </CountDiv>
        ) )}
      </CountDivWrapper>
    </Container>
  );
}

const GroupItemContainer = styled.div`
  .item-name {
    margin-bottom: 8px;
    
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    letter-spacing: -0.44px;
  }
  .item-desc {
    padding: 16px;
    background-color: #141414;

    font-family: AppleSDGothicNeo;
    font-size: 10px;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: -0.63px;
  }
  .add-margin-bottom {
    margin-bottom: 40px;
  }
`;

function GroupItem({ itemName, itemDesc }) {
  return (
    <GroupItemContainer>
      <div className="item-name"><span>{itemName}</span></div>
      <div className="item-desc add-margin-bottom"><span>{itemDesc}</span></div>
    </GroupItemContainer>
  );
}

export default Group;
