import React from 'react';
import styled from 'styled-components';
import { OuterWrapper } from './Main';
import { partners } from '../common/images';

const Container = styled.section`
  width: 100%;
  height: 848px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleDiv = styled.div`
  margin-bottom: 24px;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: -0.94px;

  text-align: center;
`;
const IconDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  /* margin-bottom: -16px; */
`;
const Icon = styled.div`
  margin-bottom: 16px;
  overflow: hidden;

  width: ${({ phoneSize }) => {
    switch ( phoneSize ) {
    case 'xs': return '130px';
    case 's': return '145px';
    default: return '152px';
    }
  }};
  height: 48px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

function Partners({ phoneSize }) {
  const getModifiedHeight = React.useCallback( ( _phoneSize, heightPx ) => {
    switch ( _phoneSize ) {
    case 'xs': return `${heightPx - 5}px`;
    case 's': return `${heightPx - 2}px`;
    default: return `${heightPx}px`;
    }
  }, []);

  const getPartnerStyle = React.useCallback( ( style ) => {
    const { height } = style;
    if ( !height ) {
      return style;
    }
    const heightPx = +( height.replace( 'px', '' ) );
    return { ...style, height: getModifiedHeight( phoneSize, heightPx ) };
  }, [ phoneSize ]);

  return (
    <Container>
      <OuterWrapper phoneSize={phoneSize} style={{ height: '680px' }}>
        <TitleDiv><span>Partners</span></TitleDiv>
        <IconDiv>
          {partners.map( ( partner ) => <Icon phoneSize={phoneSize} key={partner.name}><img src={partner.src} alt={partner.name} style={getPartnerStyle( partner.mStyle )} /></Icon> )}
        </IconDiv>
      </OuterWrapper>
    </Container>
  );
}

export default Partners;
