import React from 'react';
import styled from 'styled-components';

import { roadmapContents } from '../../meta/Roadmap';

const Container = styled.section`
  min-width: 1250px;
  overflow: hidden;
`;

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 1050px;
`;

const Title = styled( Wrapper )`
  font-family: GmarketSans;
  font-size: 56px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -1.75px;
  .title-white {
    color: #FFFFFF;
  }
  .title-white-marginbottom {
    margin-bottom: 40px;
  }
  .title-blue {
    color: #4874f6;
  }
  .title-white-sub {
    font-family: GmarketSans;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.75px;
    color: #fff;
    margin-top: 40px;
    margin-bottom: 12px;
  }
`;

// roadmap
const RoadmapWrapper = styled.div`
  width: 100%;
  min-width: 1050px;
  height: 850px;
  background-color: #141414;
  padding-top: 120px;
  overflow: hidden;
`;

const RoadmapContainer = styled( Wrapper )`
  margin-top: 50px;
  position: relative;
  .border {
    position: absolute;
    border: 1px solid #4874f6;
    top: 18px;
    left: 5px;
    bottom: -200px;
  }
`;

const RoadmapContent = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 30px;
  .circle {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #4874f6;
    border-radius: 50%;
    top: 14px;
    left: 1px;
  }
`;

const RoadmapYear = styled.div`
  width: 294px;
  height: 36px;
  font-family: GmarketSans;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.75px;
  color: #969696;
  padding-left: 96px;
  margin-right: 30px;
`;

const RoadmapText = styled.div`
  width: 714px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: -0.88px;
  color: #969696;
`;

function Roadmap({ lang }) {
  return (
    <Container>
      <RoadmapWrapper>
        <Title>
          <div className="title-blue">Project Roadmap</div>
        </Title>
        <RoadmapContainer>
          <div className="border" />
          {roadmapContents[lang].map( ( content ) => (
            <RoadmapContent key={`${content.year}-${lang}`}>
              <RoadmapYear>
                <div className="circle" />
                <div className="year">{content.year}</div>
              </RoadmapYear>
              <RoadmapText>
                {content.list.map( ( roadmap ) => (
                  <li key={`${content.year}-${roadmap.id}-${lang}`}>{roadmap.text}</li>
                ) )}
              </RoadmapText>
            </RoadmapContent>
          ) )}
        </RoadmapContainer>
      </RoadmapWrapper>
    </Container>
  );
}

export default Roadmap;
