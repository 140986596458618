import React from 'react';

import TabletHeader from '../Components/Tablet/Header';
import TabletNavigation from '../Components/Tablet/Navigation';
import TabletMain from '../Components/Tablet/Main';
import TabletWhitepaper from '../Components/Tablet/Whitepaper';
import TabletAbout from '../Components/Tablet/About';
import TabletMobileApp from '../Components/Tablet/MobileApp';
import TabletGroup from '../Components/Tablet/Group';
import TabletImage from '../Components/Tablet/Image';
import TabletPartners from '../Components/Tablet/Partners';
import TabletPress from '../Components/Tablet/Press';
import TabletRoadmap from '../Components/Tablet/Roadmap';
import TabletContact from '../Components/Tablet/Contact';
import TabletFooter from '../Components/Tablet/Footer';

function TabletView({ lang, tabletSize, changeLanguage }) {
  return (
    <div>
      <TabletHeader tabletSize={tabletSize} changeLanguage={changeLanguage} />
      <TabletNavigation tabletSize={tabletSize} />
      <TabletMain lang={lang} tabletSize={tabletSize} />
      <TabletWhitepaper lang={lang} tabletSize={tabletSize} />
      <TabletAbout lang={lang} tabletSize={tabletSize} />
      <TabletMobileApp lang={lang} tabletSize={tabletSize} />
      <TabletGroup lang={lang} tabletSize={tabletSize} />
      <TabletImage lang={lang} tabletSize={tabletSize} />
      <TabletPartners lang={lang} tabletSize={tabletSize} />
      <TabletPress lang={lang} tabletSize={tabletSize} />
      <TabletRoadmap lang={lang} tabletSize={tabletSize} />
      <TabletContact lang={lang} tabletSize={tabletSize} />
      <TabletFooter tabletSize={tabletSize} />
    </div>
  );
}

export default TabletView;
