export const roadmapContents = {
  ko: [
    {
      year: '-2021',
      list: [
        { id: '01', text: 'apM 멤버스 모바일 앱 개발' },
        { id: '02', text: '고객 리워드 적립 및 시장 데이터 수집을 위한 인프라 구축' },
        { id: '03', text: 'apM 전자상품권 인앱(In-app) 결제를 위한 자체 결제 시스템 개발' },
      ],
    },
    {
      year: 'Q1-Q2 2022',
      list: [
        { id: '01', text: 'apM 멤버스 앱 공식 출시' },
        { id: '02', text: '대한민국 중소벤처기업부 인증 벤처기업확인서 취득' },
        { id: '03', text: 'On-Chan NFT 전자상품권 서비스 구축 및 화이트리스트 대상 도입' },
        { id: '04', text: 'apM 쇼핑몰 부대시설 중심의 고객 리워드 서비스 운영 시스템 도입' },
      ],
    },
    {
      year: 'Q3-Q4 2022',
      list: [
        { id: '01', text: 'apM 멤버스 활용 고객 리워드 적립/사용 부문 서비스 활성화' },
        { id: '02', text: 'apM 코인 디지털 자산 활용 인앱(In-app) 서비스 도입' },
      ],
    },
    {
      year: 'Q1-Q2 2023',
      list: [
        { id: '01', text: '기존 프로덕트 및 컨텐츠 개선 작업' },
        { id: '02', text: '멀티체인 지원을 위한 주요 서비스 코어 로직 재정비' },
      ],
    },
    {
      year: 'Q3-Q4 2023',
      list: [
        { id: '01', text: '리워드 레이어 개발' },
        { id: '02', text: '멤버십 서비스 개발' },
      ],
    },
    {
      year: '2023-2024',
      list: [
        { id: '01', text: 'AI 연계 서비스 발굴' },
        { id: '02', text: '리워드 획득 및 활용처 확대' },
        { id: '03', text: '생태계 확장을 위한 파트너사의 글로벌 확장 지원' },
      ],
    },
  ],
  en: [
    {
      year: '-2021',
      list: [
        { id: '01', text: 'Develop apM Members mobile app' },
        { id: '02', text: 'Build infrastructure for earning customer rewards and collecting market data' },
        { id: '03', text: 'Develop a payment system for apM digital voucher in-app payment' },
      ],
    },
    {
      year: 'Q1-Q2 2022',
      list: [
        { id: '01', text: 'Launch apM Members mobile app' },
        { id: '02', text: 'Acquire the certificate of venture business by Ministry of SMEs and Startup in the Republic of Korea' },
        { id: '03', text: 'Develop On-Chain apM NFT Voucher and Introduction for whitelist customers' },
        { id: '04', text: 'Deploy a customer rewards operation system in apM shopping malls: starting from the malls’ facilities' },
      ],
    },
    {
      year: 'Q3-Q4 2022',
      list: [
        { id: '01', text: 'Expand apM Member’s service areas related to customer reward collection and redemption' },
        { id: '02', text: 'Adopt apM Coin’s digital-asset-applied in-app service' },
      ],
    },
    {
      year: 'Q1-Q2 2023',
      list: [
        { id: '01', text: 'Enhancements to existing products and content' },
        { id: '02', text: 'Overhaul key service core logic for multi-chain support' },
      ],
    },
    {
      year: 'Q3-Q4 2023',
      list: [
        { id: '01', text: 'Develop reward layer' },
        { id: '02', text: 'Develop membership services' },
      ],
    },
    {
      year: '2023-2024',
      list: [
        { id: '01', text: 'Discover AI-linked services' },
        { id: '02', text: 'Expand reward acquisition and utilization' },
        { id: '03', text: 'Support partners` global expansion to expand the ecosystem' },
      ],
    },
  ],
};

export const mock = {

};
