import React from 'react';
import styled from 'styled-components';

import Link from '../../meta/Contact';
import { contact } from '../common/images';

const {
  medium, twitter, naver, telegramGlobal, telegramKorean, telegramAnnouncement,
} = Link;

const {
  mediumIcon, twitterIcon, naverIcon, telegramIcon,
} = contact;

const Container = styled.section`
  min-width: 1250px;
  overflow: hidden;
  background-color: #000000;
  font-family: GmarketSans;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 460px;
  margin: 0 auto;
  max-width: 1050px;
  display: flex;
  justify-content: space-between;
`;

const ContactWrapper = styled.div`
  margin: 100px 0 0 0;
  width: 100%;

  font-weight: 500;
  font-stretch: normal;
  font-style: normal;

  .contact-title {
    font-family: GmarketSans;
    font-size: 56px;
    line-height: 1.43;
    letter-spacing: -1.75px;
  }

  .contact-desc {
    margin-top: 20px;
    font-family: GmarketSans;
    font-size: 20px;
    line-height: 1.8;
    /* line-height: normal; */
    letter-spacing: -1px;
  }

  .contact-email {
    margin-top: 10px;
  }
`;

const EmailLink = styled.span`
  font-family: GmarketSans;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.8;
  letter-spacing: -0.63px;
  color: #4874f6;
`;

const SocialWrapper = styled.div`
  margin: 200px 0 0 0;
  width: 100%;
  height: 156px;
  display: flex;
  flex-wrap: wrap;
`;

const IconWrapper = styled.div`
  width: 240px;
  height: 32px;
  margin: 0 0 30px 0;
  font-size: ${( props ) => props.fontSize || '12px'};
  line-height: 1.67;
  letter-spacing: -0.3px;
  color: #ffffff;
  background-color: #272f3a;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:nth-child(even) {
    margin-left: 30px;
  }

  &:nth-child(n+5) {
    margin-bottom: 0;
  }

  .text-wrapper {
    padding: 0 0 0 16px;
  }

  .icon-wrapper {
    width: 32px;
    height: 32px;
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  cursor: pointer;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

function Contact() {
  const moveSocialLink = ( path ) => window.open( path );

  return (
    <Container name="contact">
      <Wrapper>
        <ContactWrapper>
          <div className="contact-title">Contact</div>
          <div className="contact-desc" />
          <div className="contact-email">
            <EmailLink>hello@apm-coin.com</EmailLink>
          </div>
        </ContactWrapper>
        <SocialWrapper>
          <IconWrapper onClick={() => moveSocialLink( medium )}>
            <div className="text-wrapper">Medium</div>
            <div className="icon-wrapper">
              <Icon src={mediumIcon} alt="medium" />
            </div>
          </IconWrapper>
          <IconWrapper onClick={() => moveSocialLink( twitter )}>
            <div className="text-wrapper">X</div>
            <div className="icon-wrapper">
              <Icon src={twitterIcon} alt="X" />
            </div>
          </IconWrapper>
          <IconWrapper onClick={() => moveSocialLink( naver )}>
            <div className="text-wrapper">Naver Blog</div>
            <div className="icon-wrapper">
              <Icon src={naverIcon} alt="naver" />
            </div>
          </IconWrapper>
          <IconWrapper onClick={() => moveSocialLink( telegramGlobal )}>
            <div className="text-wrapper">Telegram (Global)</div>
            <div className="icon-wrapper">
              <Icon src={telegramIcon} alt="telegram" />
            </div>
          </IconWrapper>
          <IconWrapper onClick={() => moveSocialLink( telegramKorean )}>
            <div className="text-wrapper">Telegram (Korean)</div>
            <div className="icon-wrapper">
              <Icon src={telegramIcon} alt="telegram" />
            </div>
          </IconWrapper>
          <IconWrapper onClick={() => moveSocialLink( telegramAnnouncement )}>
            <div className="text-wrapper">Telegram Announcement</div>
            <div className="icon-wrapper">
              <Icon src={telegramIcon} alt="telegram" />
            </div>
          </IconWrapper>
        </SocialWrapper>
      </Wrapper>
    </Container>
  );
}

export default Contact;
