import React from 'react';
import styled from 'styled-components';

import { main } from '../common/images';

const bg1 = require( '../../assets/Main/bg-web/img-bg-apmplace-01/img-bg-apmplace-01@3x.png' );

const Container = styled.section`
  min-width: 1250px;
  overflow: hidden;
`;

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 1050px;
`;

const TitleWrapperDarkly = styled.div`
  width: 100%;
  min-width: 1050px;
  height: 960px;
  background-image: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ),url(${( props ) => props.bgImage});
  background-position: center center;
  background-size: cover;
  padding-top: 120px;
`;

const Title = styled( Wrapper )`
  font-family: GmarketSans;
  font-size: 56px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -1.75px;
  .title-white {
    color: #FFFFFF;
  }
  .title-blue {
    color: #4874f6;
  }
`;

const ProjectContents = styled( Wrapper )`
  margin-top: 160px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const ProjectContentsBlockchain = styled.div`
  display: flex;
  z-index: 1;
`;

const FeatureContainer = styled.div`
  width: 510px;
  min-height: 240px;
  margin-top: 12px;
  padding: 24px;
  background-color: #000;
`;

const FeatureTitleContainer = styled.div`
  display: flex;
  align-items: end;
`;

const FeatureImage = styled.img`
  width: 72px;
  height: 72px;
  object-fit: contain;
`;

const FeatureDescription = styled.div`
  width: 462px;
  height: 96px;
  margin: 24px 0 0;
  font-family: GmarketSans;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: -0.88px;
  color: #fff;
  // 영어 문장 default
  word-break: keep-all;
`;

function Main() {
  return (
    <Container name="main">
      <TitleWrapperDarkly bgImage={bg1}>
        <Title>
          <div className="title-white">apM Coin</div>
          <div className="title-blue">Blockchain-powered</div>
          <div className="title-blue">Customer Rewards</div>
          <div className="title-blue">Management Platform</div>
        </Title>
        <ProjectContents>
          <ProjectContentsBlockchain>
            <FeatureContainer height="302px">
              <FeatureTitleContainer>
                <FeatureImage src={main.blockchainIcon} alt="blockchain" />
              </FeatureTitleContainer>
              <FeatureDescription>
                Partnered with apM Group, Korea’s leading wholesale shopping malls, apM Coin provides a customer reward management platform and a key solution for the Korea&apos;s leading wholesale fashion industry powered by blockchain technologies
              </FeatureDescription>
            </FeatureContainer>
          </ProjectContentsBlockchain>
        </ProjectContents>
      </TitleWrapperDarkly>
    </Container>
  );
}

export default Main;
