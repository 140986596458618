import React from 'react';
import styled from 'styled-components';
import { main } from '../common/images';

const { blockchainIcon, mainBackground } = main;

// const apmBlue = '#4874f6';
const APM_COIN_DESC = 'Blockchain-powered\nCustomer Rewards\nManagement\nPlatform';
const APM_COIN_DESC_DETAIL = 'Partnered with apM Group, Korea’s leading wholesale shopping malls, apM Coin provides a customer reward management platform and a key solution for the Korea\'s leading wholesale fashion industry powered by blockchain technologies';

const Container = styled.section`
  width: 100%;
  height: ${({ phoneSize }) => {
    switch ( phoneSize ) {
    case 's': return '560px';
    default: return '720px';
    }
  }};

  display: flex;
  align-items: center;
  justify-content: center;

  background-blend-mode: multiply;
  background-image: linear-gradient(to bottom, #6d6d6d, #6d6d6d), ${({ imageSrc }) => `url(${imageSrc})`};
  background-size: 100%;
  background-position: center top;
`;
export const OuterWrapper = styled.div`
  width: 340px;
  padding: ${({ phoneSize }) => {
    switch ( phoneSize ) {
    case 's': return '0 12px';
    case 'xs': return '0 7px';
    default: return '0px';
    }
  }};
  box-sizing: border-box;

  font-family: GmarketSans;
  white-space:pre-wrap;
  color: #fff;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  .color-apm-blue {
    color: #4874f6;
  }
`;
const ModifiedOuterWrapper = styled( OuterWrapper )`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;
const Title = styled.div`
  font-size: ${({ phoneSize }) => {
    switch ( phoneSize ) {
    case 's': return '27px';
    case 'xs': return '24px';
    default: return '30px';
    }
  }};
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: -0.94px;
`;
const Desc = styled.div`
  padding: 16px;
  background-color: #000;

  font-size: 10px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: -0.63px;

  .img {
    margin-bottom: 16px;
  }
`;

function Main({ phoneSize }) {
  return (
    <Container name="main" imageSrc={mainBackground} phoneSize={phoneSize}>
      <ModifiedOuterWrapper phoneSize={phoneSize}>
        <Title phoneSize={phoneSize}>
          <div><span>apM Coin:</span></div>
          <div><span className="color-apm-blue">{APM_COIN_DESC}</span></div>
        </Title>

        <Desc>
          <div className="img"><img src={blockchainIcon} style={{ width: '56px' }} alt="blockchain-icon" /></div>
          <div><span>{APM_COIN_DESC_DETAIL}</span></div>
        </Desc>
      </ModifiedOuterWrapper>
    </Container>
  );
}

export default Main;
