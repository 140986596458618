import React from 'react';
import styled from 'styled-components';

import { group, globalMarket, count } from '../../meta/Group';

const bg = require( '../../assets/Group/bg-web/img-bg-apmplace-02/img-bg-apmplace-02@3x.png' );

const Container = styled.section`
  min-width: 1250px;
`;

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 1050px;
`;

const TitleWrapper = styled.div`
  width: 100%;
  min-width: 1050px;
  height: 720px;
  background-image: linear-gradient( rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8) ),url(${( props ) => props.bgImage});
  background-position: center center;
  background-size: cover;
  padding-top: 120px;
`;

const Title = styled( Wrapper )`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: GmarketSans;
  font-size: 56px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -1.75px;
  color: #4874f6;
`;

const AboutGroupWrapper = styled.div`
  width: 100%;
  margin-top: ${({ marginTop }) => ( marginTop )};

  .about-group-title {
    font-family: GmarketSans;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.75px;
  }

  .about-group-detail {
    display: flex;
    justify-content: space-between;
  }
`;

const AboutGroup = styled( Wrapper )`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CountGroupWrapper = styled.div`
  width: 100%;
  height: 240px;
  background-color: #19202a;
`;

const CountGroup = styled( Wrapper )`
  min-width: 1050px;
  height: 100%;
  padding-top: 50px;
  display: flex;
  justify-content: space-around;
`;

const Count = styled.div`
  .count-value {
    font-family: GmarketSans;
    font-size: 56px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -1.75px;
    text-align: center;
    color: #4874f6;
  }
  .count-unit {
    font-family: GmarketSans;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.94px;
    color: #4874f6;
    margin-top: 35px;
  }
  .count-title {
    font-size: ${( props ) => ( props.lang === 'en' ? '16px' : '24px' )};
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: -0.75px;
    text-align: center;
    color: #969696;
    white-space: pre-wrap;
  }
`;

const CountUnitContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const DescriptionWrapper = styled.div`
  min-width: 1050px;
  position: relative;
  margin: -3px 0;
  video {
    max-width: 100%;
    width: 100%;
    height: 360px;
    margin: 0;
    line-height: 1;
    border: none;
    filter: grayscale(100%);
    object-fit: cover;
  }

  .description-group {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const GroupIntroduction = styled.div`
  .group-introduction-detail {
    width: 510px;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: -0.88px;
    word-break: ${( props ) => ( props.lang === 'ch' ? 'break-all' : 'keep-all' )};
    white-space: pre-wrap;
  }
`;

function Group({ lang }) {
  return (
    <Container name="group">
      <TitleWrapper bgImage={bg}>
        <Title>
          apM Group:
          <br />
          The Partner of apM Coin
        </Title>
        <AboutGroupWrapper marginTop="80px">
          <AboutGroup>
            <div className="about-group-title">
              apM Group
            </div>
            <div className="about-group-detail">
              {group[lang].map( ( el ) => (
                <GroupIntroduction lang={lang} key={el.id}>
                  <div className="group-introduction-detail">{el.text}</div>
                </GroupIntroduction>
              ) )}
            </div>
          </AboutGroup>
        </AboutGroupWrapper>
        <AboutGroupWrapper marginTop="22px">
          <AboutGroup>
            <div className="about-group-title">
              apM Group in Global Market
            </div>
            <div className="about-group-detail">
              {globalMarket[lang].map( ( el ) => (
                <GroupIntroduction lang={lang} key={el.id}>
                  <div className="group-introduction-detail">{el.text}</div>
                </GroupIntroduction>
              ) )}
            </div>
          </AboutGroup>
        </AboutGroupWrapper>
      </TitleWrapper>

      <CountGroupWrapper>
        <CountGroup>
          {count[lang].map( ( el ) => (
            <Count lang={lang} key={el.id}>
              <CountUnitContainer>
                <div className="count-value">{el.value}</div>
                <div className="count-unit">{el.unit}</div>
              </CountUnitContainer>
              <div className="count-title">{el.title}</div>
            </Count>
          ) )}
        </CountGroup>
      </CountGroupWrapper>
      <DescriptionWrapper>
        <video src="https://cdn.apm-coin.com/201201_web_main.mp4" autoPlay loop muted="muted" playsInline controlsList="nodownload">
          <track kind="captions" />
        </video>
      </DescriptionWrapper>
    </Container>
  );
}

export default Group;
