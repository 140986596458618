import React from 'react';
import styled from 'styled-components';

import { mobileApp } from '../common/images';
import { mobileAppDescription, address } from '../../meta/MobileApp';

const bg = require( '../../assets/MobileApp/bg-web/img-bg-phonemockup-main/img-bg-phonemockup-main@3x.png' );

const Container = styled.section`
  min-width: 1250px;
  overflow: hidden;
`;

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 1050px;
`;

const Title = styled( Wrapper )`
  font-family: GmarketSans;
  font-size: 56px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -1.75px;
  .title-blue {
    color: #4874f6;
  }
`;

const TitleWrapperMembers = styled.div`
  width: 100%;
  min-width: 1050px;
  height: 960px;
  background-color: #141414;
  z-index: 0;
  padding-top: 120px;
`;

const MembersWrapper = styled.div`
  width: 1050px;
  min-width: 1050px;
  height: 840px;
  margin: 0px auto;
  position: relative;
`;

const MembersBackgroundImg = styled.div`
  width: 960px;
  height: 840px;
  background-image: url(${( props ) => props.bgImage});
  background-position: center bottom;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 0;
  position: absolute;
  right: -195px;
  bottom: 0;
`;

const MembersMockImage = styled.img`
  width: 72px;
  height: 72px;
  margin-top: 256px;
`;

const MembersIntroduce = styled.div`
    .name {
      width: 510px;
      height: 36px;
      margin: 24px 0 12px;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: -0.75px;
      color: #fff;
      font-family: GmarketSans;
    }
    .description {
      width: 450px;
      margin: 12px 48px 20px 0;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: -0.88px;
      color: #fff;
      word-break: ${( props ) => ( props.lang === 'ch' ? 'break-all' : 'keep-all' )};
    }
`;

const MembersMoreinfoContainer = styled.div`
  width: 462px;
  height: 32px;
  margin-top: 24px;
  display: flex;
  justify-content: flex-start;
  z-index: 1;
`;

const MoreinfoText = styled.div`
  width: 208px;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 0 0 16px;
  background-color: #4874f6;
  font-family: GmarketSans;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.88px;
  color: #fff;
  cursor: pointer;
`;

const MoreinfoButton = styled.button`
  width: 32px;
  height: 32px;
  padding: 4px;
  background-color: #000;
  cursor: pointer;
  border: none;
`;

const MoreinfoNextButtonImg = styled.img`
  width: 24px;
  height: 24px;
`;

function MobileApp({ lang }) {
  const movePage = React.useCallback( ( path ) => {
    window.open( path );
  }, []);

  return (
    <Container name="mobileApp">
      <TitleWrapperMembers>
        <MembersWrapper>
          <MembersBackgroundImg bgImage={bg} />
          <Title>
            <div className="title-blue">Blockchain-Applied</div>
            <div className="title-blue">Mobile App Service for</div>
            <div className="title-blue">Wholesale Fashion Malls</div>
          </Title>
          <MembersMockImage src={mobileApp.appIcon} alt="app-icon" />
          <MembersIntroduce lang={lang}>
            <div className="name">apM Members Mobile App</div>
            <div className="description">{mobileAppDescription[lang]}</div>
          </MembersIntroduce>
          <MembersMoreinfoContainer>
            <MoreinfoText onClick={() => movePage( address.mobileApp )}>More info</MoreinfoText>
            <MoreinfoButton onClick={() => movePage( address.mobileApp )}>
              <MoreinfoNextButtonImg src={mobileApp.nextIcon} alt="next" />
            </MoreinfoButton>
          </MembersMoreinfoContainer>
        </MembersWrapper>
      </TitleWrapperMembers>
    </Container>
  );
}

export default MobileApp;
