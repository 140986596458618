import React from 'react';
import styled from 'styled-components';

import { roadmapContents } from '../../meta/Roadmap';
import { OuterWrapper } from './Main';

const Container = styled.section`
  width: 100%;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  height: ${({ lang }) => ( lang === 'ko' ? '800px' : '840px' )};
  background-color: #141414;
  padding-top: 40px;
`;

const Wrapper = styled.div`
`;

const Title = styled( Wrapper )`
  font-family: GmarketSans;
  font-size: 56px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -1.75px;
  .title-blue {
    color: #4874f6;
  }
`;

// roadmap
const RoadmapContainer = styled( Wrapper )`
  margin-top: 50px;
  position: relative;
  .border {
    position: absolute;
    border: 1px solid #4874f6;
    top: 18px;
    left: 5px;
    bottom: -200px;
  }
`;

const RoadmapContent = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 30px;
  .circle {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #4874f6;
    border-radius: 50%;
    top: 14px;
    left: 1px;
  }
`;

const RoadmapYear = styled.div`
  width: 250px;
  height: 36px;
  font-family: GmarketSans;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.0;
  letter-spacing: -0.75px;
  color: #969696;
  padding-left: 30px;
  margin-right: 20px;
`;

const RoadmapText = styled.div`
  width: 714px;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.0;
  letter-spacing: -0.88px;
  color: #969696;
  margin-top: 5px;

  li {
    display: table;
    list-style: none;
    word-break: keep-all;
  }
  li::before {
    content: "\u00B7";
    font-weight: 900;
    zoom: 1;
    display: table-cell;
    text-align: right;
    padding-right: 1em;
  }
`;

function Roadmap({ lang, tabletSize }) {
  return (
    <Container lang={lang}>
      <OuterWrapper tabletSize={tabletSize}>
        <Title>
          <div className="title-blue">Project Roadmap</div>
        </Title>
        <RoadmapContainer>
          <div className="border" />
          {roadmapContents[lang].map( ( content ) => (
            <RoadmapContent key={`${content.year}-${lang}`}>
              <RoadmapYear>
                <div className="circle" />
                <div className="year">{content.year}</div>
              </RoadmapYear>
              <RoadmapText>
                {content.list.map( ( roadmap ) => (
                  <li key={`${content.year}-${roadmap.id}-${lang}`}>{roadmap.text}</li>
                ) )}
              </RoadmapText>
            </RoadmapContent>
          ) )}
        </RoadmapContainer>
      </OuterWrapper>
    </Container>
  );
}

export default Roadmap;
