export const pressNews = [
  {
    id: 'news-01', newspaper: '전자신문', title: '에이피엠 코인 프로젝트, DWF 랩스서 투자유치', link: 'https://www.etnews.com/20230331000155',
  },
  {
    id: 'news-02', newspaper: '스포츠서울', title: '석광일 apM S&S 대표 ‘동대문 의류 시장 결제 시스템의 혁신을 이뤄내겠다’', link: 'http://www.sportsseoul.com/news/read/1150876',
  },
  {
    id: 'news-03', newspaper: '아시아경제', title: '에이피엠 에스앤에스, 현금처럼 사용가능한 ‘에이피엠 전자상품권’ 성공적 도입', link: 'https://view.asiae.co.kr/article/2022082410451833634',
  },
  {
    id: 'news-04', newspaper: '이데일리', title: '에이피엠 에스앤에스, 에이피엠 멤버스 통해 ‘NFT 전자상품권’ 출시', link: 'https://www.edaily.co.kr/news/read?newsId=01708886632295136',
  },
  {
    id: 'news-05', newspaper: '파이낸셜 뉴스', title: '에이피엠 에스앤에스, 자체 모바일 앱 서비스 ‘에이피엠 멤버스’ 글로벌 시장 진출', link: 'https://www.fnnews.com/news/202204041104256143',
  },
  {
    id: 'news-06', newspaper: '머니 S', title: '연거래액 8조원 규모 동대문의류 B2B 쇼핑몰에 ‘전자상품권’ 최초 도입', link: 'https://moneys.mt.co.kr/news/mwView.php?no=2021082510488010714',
  },
  // {
  //   id: 'news-03', newspaper: '스포츠서울', title: '에이피엠 에스앤에스, 에이피엠 전자상품권 서비스 런칭', link: 'http://www.sportsseoul.com/news/read/1149089',
  // },
  // {
  //   id: 'news-07', newspaper: '테크월드', title: '에이피엠 코인 프로젝트, 모바일 앱 에이피엠 멤버스 정식 론칭', link: 'https://www.epnc.co.kr/news/articleView.html?idxno=220874',
  // },
  // {
  //   id: 'news-07', newspaper: '테크월드', title: '에이피엠 코인 프로젝트, 모바일 앱 에이피엠 멤버스 정식 론칭', link: 'https://www.epnc.co.kr/news/articleView.html?idxno=220874',
  // },
];

export const mock = {

};
