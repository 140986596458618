import React from 'react';
import styled from 'styled-components';

import { partners } from '../common/images';

const Container = styled.section`
  min-width: 1250px;
  overflow: hidden;
`;

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 1050px;
`;

const PartnersWrapper = styled.div`
  width: 100%;
  min-width: 1050px;
  height: 980px;
  background-color: #141414;
  padding-top: 120px;
`;

const PartnersContainer = styled( Wrapper )`
  .partners-title {
    width: 495px;
    height: 80px;
    font-family: GmarketSans;
    font-size: 56px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -1.75px;
    color: #fff;
  }
  .partners-logos {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

  }
  .partners-text {
    width: 1050px;
    height: 36px;
    margin: 40px 0 0;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.75px;
    color: #fff;
  }
`;

const PartnerLogo = styled.div`
  width: 240px;
  height: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
`;

const PartnerLogoImg = styled.img`
  height: 45px;
`;

function Partners({ lang }) {
  return (
    <Container>
      <PartnersWrapper>
        <PartnersContainer>
          <div className="partners-title">Partners</div>
          <div className="partners-logos">
            {partners.map( ( partnerLogo ) => (
              <PartnerLogo key={partnerLogo.name}>
                <PartnerLogoImg src={partnerLogo.src} alt={partnerLogo.name} style={partnerLogo.style} />
              </PartnerLogo>
            ) )}
          </div>
        </PartnersContainer>
      </PartnersWrapper>
    </Container>
  );
}

export default Partners;
