import React from 'react';
import styled from 'styled-components';

const Container = styled.section`
  width: 100%;
  height: 240px;

  video {
    max-width: 100%;
    width: 100%;
    height: 240px;
    margin: 0;
    line-height: 1;
    border: none;
    filter: grayscale(100%);
    object-fit: cover;
  }
`;

function Image() {
  // TODO: iphone 12 mini에서 영상 안 나옴
  return (
    <Container>
      <video src="https://cdn.apm-coin.com/201201_web_main.mp4" autoPlay loop muted="muted" playsInline controlsList="nodownload">
        <track kind="captions" />
      </video>
    </Container>
  );
}

export default Image;
