import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';

import { OuterWrapper } from './Main';
import { header } from '../common/images';

const Container = styled.section`
  width: 100%;
  height: 96px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: GmarketSans;
  background-color: #000000;
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Logo = styled.img`
  width: 156px;
  height: 34px;
`;

const NavWrapper = styled.nav``;

const Navs = styled.ul`
  display: flex;
  align-items: center;
  font-size: 16px;
`;

const Nav = styled.li`
  margin: 0 0 0 24px;
  cursor: pointer;
  &:hover {
    font-weight: bold;
  }
`;

function Navigation({ tabletSize }) {
  return (
    <Container>
      <OuterWrapper tabletSize={tabletSize}>
        <InnerWrapper>
          <Logo src={header.logoImg} alt="apM_Coin" />
          <NavWrapper>
            <Navs>
              <Nav>
                <Link to="about" spy smooth duration={500}>apM Coin</Link>
              </Nav>
              <Nav>
                <Link to="mobileApp" spy smooth duration={500}>apM Members</Link>
              </Nav>
              <Nav>
                <Link to="group" spy smooth duration={500}>apM Group</Link>
              </Nav>
              <Nav>
                <Link to="contact" spy smooth duration={500}>Contact</Link>
              </Nav>
            </Navs>
          </NavWrapper>
        </InnerWrapper>
      </OuterWrapper>
    </Container>
  );
}

export default Navigation;
