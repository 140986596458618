import React from 'react';
import styled from 'styled-components';
import { OuterWrapper } from './Main';
import { press } from '../common/images';
import { pressNews } from '../../meta/Press';

const { outlinkIcon } = press;

const Container = styled.section`
  width: 100%;
  height: 850px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #19202a;
`;
const Title = styled.div`
  margin-bottom: 24px;

  font-size: 30px;
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: -0.94px;
  text-align: center;
  .press-korean {
    font-size: 15px;
  }
`;
const ArticleItem = styled.div`
  position: relative;

  min-height: 92px;
  padding: 16px;
  margin-bottom: 16px;

  background-color: #000;

  .publisher {
    margin-bottom: 4px;

    font-family: AppleSDGothicNeo;
    font-size: 10px;
    font-weight: bold;
    line-height: 1.6;
    letter-spacing: -0.31px;
    color: #4874f6;
  }
`;
const ArticleTitle = styled.div`
  font-family: AppleSDGothicNeo;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: -0.44px;
  text-decoration: underline;
`;
const OutLinkIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4874f6;

  position: absolute;
  right: 0;
  bottom: 0;

  width: 24px;
  height: 24px;
`;

function Press({ phoneSize }) {
  const moveLink = React.useCallback( ( path ) => {
    window.open( path );
  }, []);

  return (
    <Container>
      <OuterWrapper phoneSize={phoneSize}>
        <Title>
          <span>Press</span>
          <span className="press-korean"> (Korean)</span>
        </Title>

        {pressNews.map( ( article ) => (
          <ArticleItem key={article.id}>
            <div className="publisher">{article.newspaper}</div>
            <ArticleTitle onClick={() => moveLink( article.link )}>{article.title}</ArticleTitle>
            <OutLinkIcon onClick={() => moveLink( article.link )}><img src={outlinkIcon} alt="outlink-icon" style={{ height: '18px' }} /></OutLinkIcon>
          </ArticleItem>
        ) )}
      </OuterWrapper>
    </Container>
  );
}

export default Press;
