import React from 'react';
import styled from 'styled-components';

import { about } from '../common/images';
import { assetsVoucher, assetsBridge, address } from '../../meta/About';
import { OuterWrapper } from './Main';

const bg = require( '../../assets/About/bg-web/img-bg-blockchain-01/img-bg-blockchain-01@3x.png' );

const Container = styled.section`
  width: 100%;
  height: 1320px;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  padding: 120px 0;

  background-image: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ),url(${( props ) => props.bgImage});
  background-blend-mode: multiply;
  background-position: center center;
  background-size: cover;
`;
const ModifiedOuterWrapper = styled( OuterWrapper )`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Title = styled.div`
  font-family: GmarketSans;
  font-size: 56px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -1.75px;
  .title-white {
    color: #FFFFFF;
  }
  .title-white-sub {
    font-family: GmarketSans;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.75px;
    color: #fff;
    margin-top: 40px;
    margin-bottom: 12px;
  }
`;

const AvailableContainer = styled.div`
  position: absolute;
  top: 335px;

  width: 100%;
  padding: 60px 0;
  background-color: #141414;
`;
const AvailableTitle = styled.div`
  font-family: GmarketSans;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.75px;
  text-align: center;
  color: #fff;
`;
const AvailableImgContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
const AvailableDiagram = styled.img`
  width: 85%;
  max-width: 750px;
  margin-top: 40px;
`;
const AboutContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 60px;
`;

const AboutContents = styled.div`
  .about-contents-title {
    width: 300px;
    height: 36px;
    margin: 0 0 12px;
    font-family: GmarketSans;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.75px;
    color: #fff;
  }
`;
const AboutContentsContainer = styled.div`
  width: 300px;
  height: 300px;
  padding-left: 24px;
  background-color: #1e1e1e;
`;
const AboutContentsVoucherImg = styled.img`
  height: 72px;
  margin-top: 24px;
  margin-bottom: 30px;
`;
const AboutContentsApmBridgeImg = styled.img`
  height: 72px;
  margin-top: 24px;
  margin-bottom: 30px;
`;
const AboutContentsDescription = styled.div`
  width: 100%;
  height: 72px;
  font-family: AppleSDGothicNeo;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: -0.88px;
  color: #fff;
  word-break: ${( props ) => ( props.lang === 'ch' ? 'break-all' : 'keep-all' )};
`;

const MoreinfoContainer = styled.div`
  height: 32px;
  margin-left: 40px;
  margin-top: 32px;
  display: flex;
  z-index: 1;
`;

const MoreinfoText = styled.div`
  width: 180px;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 0 0 16px;
  background-color: #4874f6;
  font-family: GmarketSans;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.88px;
  color: #fff;
  cursor: pointer;
`;

const MoreinfoButton = styled.button`
  width: 32px;
  height: 32px;
  padding: 4px;
  background-color: #000;
  cursor: pointer;
  border: none;
`;
const MoreinfoNextButtonImg = styled.img`
  width: 24px;
  height: 24px;
`;

function About({ lang, tabletSize }) {
  const movePage = React.useCallback( ( path ) => {
    window.open( path );
  }, []);

  return (
    <Container name="about" bgImage={bg}>
      <ModifiedOuterWrapper tabletSize={tabletSize}>

        <Title>
          <div className="title-white">About apM Coin</div>
          <div className="title-white-sub">Securely Managed on apM Chain Platform</div>
        </Title>

        <AboutContainer>
          <AboutContents>
            <div className="about-contents-title">apM NFT Voucher</div>
            <AboutContentsContainer>
              <AboutContentsVoucherImg src={about.evoucherIcon} alt="voucher" />
              <AboutContentsDescription lang={lang}>
                {assetsVoucher[lang]}
              </AboutContentsDescription>
            </AboutContentsContainer>
          </AboutContents>
          <AboutContents>
            <div className="about-contents-title">apM Bridge</div>
            <AboutContentsContainer>
              <AboutContentsApmBridgeImg src={about.blockchainIcon2} alt="apM-bridge" />
              <AboutContentsDescription lang={lang}>
                {assetsBridge[lang]}
              </AboutContentsDescription>

              <MoreinfoContainer>
                <MoreinfoText onClick={() => movePage( address.bridge )}>More info</MoreinfoText>
                <MoreinfoButton onClick={() => movePage( address.bridge )}>
                  <MoreinfoNextButtonImg src={about.nextIcon} alt="next" />
                </MoreinfoButton>
              </MoreinfoContainer>
            </AboutContentsContainer>
          </AboutContents>
        </AboutContainer>

      </ModifiedOuterWrapper>

      {/* <AvailableContainer>
        <AvailableTitle>
          Availaable on
        </AvailableTitle>
        <AvailableImgContainer>
          <AvailableDiagram src={about.aboutDiagramWeb} alt="diagram" />
        </AvailableImgContainer>
      </AvailableContainer> */}
    </Container>
  );
}

export default About;
