const Link = {
  twitter: 'https://twitter.com/apmcoin',
  naver: 'https://blog.naver.com/apminnovation',
  medium: 'https://medium.com/apmcoin',
  telegramGlobal: 'https://t.me/apmcoin_official',
  telegramKorean: 'https://t.me/apmcoin_kor',
  telegramAnnouncement: 'https://t.me/apmcoin_ann',
};

export default Link;
