import React from 'react';
import styled from 'styled-components';

const Container = styled.section`
  width: 100%;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #141414;

  font-family: GmarketSans;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.6;
  letter-spacing: -0.31px;
  color: #969696;
`;

function Footer() {
  return (
    <Container>© DA Network Pte. Ltd., Since 2019. All Rights Reserved.</Container>
  );
}

export default Footer;
