import React from 'react';

import MobileHeader from '../Components/Mobile/Header';
import MobileMain from '../Components/Mobile/Main';
import MobileWhitepaper from '../Components/Mobile/Whitepaper';
import MobileAbout from '../Components/Mobile/About';
import MobileMobileApp from '../Components/Mobile/MobileApp';
import MobileGroup from '../Components/Mobile/Group';
import MobileImage from '../Components/Mobile/Image';
import MobilePartners from '../Components/Mobile/Partners';
import MobilePress from '../Components/Mobile/Press';
import MobileRoadmap from '../Components/Mobile/Roadmap';
import MobileContact from '../Components/Mobile/Contact';
import MobileFooter from '../Components/Mobile/Footer';

function MobileView({ lang, phoneSize, changeLanguage }) {
  const [ isOpen, setIsOpen ] = React.useState( false );

  const changeToggleState = () => setIsOpen( ( prev ) => !prev );

  const switchOffToggle = () => setIsOpen( false );

  const menu = { isOpen, changeToggleState, switchOffToggle };

  const onClickLanguage = ( langValue ) => {
    changeLanguage( langValue );
    changeToggleState();
  };

  return (
    <div>
      <MobileHeader phoneSize={phoneSize} menu={menu} changeLanguage={onClickLanguage} />
      <MobileMain lang={lang} phoneSize={phoneSize} />
      <MobileWhitepaper lang={lang} phoneSize={phoneSize} />
      <MobileAbout lang={lang} phoneSize={phoneSize} />
      <MobileMobileApp lang={lang} phoneSize={phoneSize} />
      <MobileGroup lang={lang} phoneSize={phoneSize} />
      <MobileImage lang={lang} phoneSize={phoneSize} />
      <MobilePartners lang={lang} phoneSize={phoneSize} />
      <MobilePress lang={lang} phoneSize={phoneSize} />
      <MobileRoadmap lang={lang} phoneSize={phoneSize} />
      <MobileContact lang={lang} phoneSize={phoneSize} />
      <MobileFooter phoneSize={phoneSize} />
    </div>
  );
}

export default MobileView;
