import React from 'react';
import styled from 'styled-components';

import Link from '../../meta/Contact';
import { contact } from '../common/images';
import { OuterWrapper } from './Main';

const {
  medium, twitter, naver, telegramGlobal, telegramKorean, telegramAnnouncement,
} = Link;

const {
  mediumIcon, twitterIcon, telegramIcon, naverIcon,
} = contact;

const Container = styled.section`
  width: 100%;
  height: 640px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: GmarketSans;
  color: #ffffff;
  background-color: #000000;

  .color-blue {
    color: #4874f6;
  }
`;

const InnerWrapper = styled.div`
  height: 640px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ContactWrapper = styled.div`
  margin: 120px 0 0 0;
  font-size: 20px;

  .contact-title {
    font-size: 56px;
  }

  .contact-text {
    margin: 12px 0 24px 0;
  }

  .contact-email {
    margin: 10px 0 0 0;
    font-weight: bold;
  }
`;

const SocialWrapper = styled.div`
  margin: 0 0 120px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
`;

const IconWrapper = styled.div`
  width: 240px;
  height: 32px;
  margin: 0 0 30px 0;
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: -0.3px;
  color: #000000;
  background-color: #ffffff;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &:nth-child(even) {
    margin-left: 30px;
  }

  &:nth-child(n+5) {
    margin-bottom: 0;
  }

  .icon-wrapper {
    width: 32px;
    height: 32px;
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text-wrapper {
    padding: 0 0 0 16px;
  }

  &:last-child {
    .text-wrapper {
      padding: 0 0 0 16px;
      font-size: 10px;
    }
  }
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

function Contact({ tabletSize }) {
  const moveSocialLink = ( path ) => window.open( path );

  return (
    <Container name="contact">
      <OuterWrapper tabletSize={tabletSize}>
        <InnerWrapper>
          <ContactWrapper>
            <div className="contact-title">Contact</div>
            <div className="contact-text" />
            <div className="contact-email color-blue">hello@apm-coin.com</div>
          </ContactWrapper>
          <SocialWrapper>
            <IconWrapper onClick={() => moveSocialLink( medium )}>
              <div className="text-wrapper">Medium</div>
              <div className="icon-wrapper">
                <Icon src={mediumIcon} alt="medium" />
              </div>
            </IconWrapper>
            <IconWrapper onClick={() => moveSocialLink( twitter )}>
              <div className="text-wrapper">X</div>
              <div className="icon-wrapper">
                <Icon src={twitterIcon} alt="X" />
              </div>
            </IconWrapper>
            <IconWrapper onClick={() => moveSocialLink( naver )}>
              <div className="text-wrapper">Naver Blog</div>
              <div className="icon-wrapper">
                <Icon src={naverIcon} alt="naver" />
              </div>
            </IconWrapper>
            <IconWrapper onClick={() => moveSocialLink( telegramGlobal )}>
              <div className="text-wrapper">Telegram Global Community</div>
              <div className="icon-wrapper">
                <Icon src={telegramIcon} alt="telegram" />
              </div>
            </IconWrapper>
            <IconWrapper onClick={() => moveSocialLink( telegramKorean )}>
              <div className="text-wrapper">Telegram Korean Community</div>
              <div className="icon-wrapper">
                <Icon src={telegramIcon} alt="telegram" />
              </div>
            </IconWrapper>
            <IconWrapper onClick={() => moveSocialLink( telegramAnnouncement )}>
              <div className="text-wrapper">Telegram Announcement Channel</div>
              <div className="icon-wrapper">
                <Icon src={telegramIcon} alt="telegram" />
              </div>
            </IconWrapper>
          </SocialWrapper>
        </InnerWrapper>
      </OuterWrapper>
    </Container>
  );
}

export default Contact;
