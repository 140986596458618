import React from 'react';
import styled from 'styled-components';
import { mobileApp } from '../common/images';
import { OuterWrapper } from './Main';
import { MoreInfoBox } from './About';
import { address } from '../../meta/MobileApp';

const { phonemockup, appIcon } = mobileApp;

const TEXTS = {
  title: 'apM Memers Mobile App',
  bidDesc: 'Blockchain-Applied\nMobile App Service\nfor Wholesale\nFashion Malls',
  smallDesc: {
    ko: '국내 의류 B2B 시장 최초 도입된 블록체인 기반\n고객 리워드 모바일 앱 서비스',
    en: 'Blockchain-powered Customer Rewards Management Platform\nfor the Korea’s Leading Wholesale Fashion Malls',
  },
};

const Container = styled.section`
  width: 100%;
  height: ${({ phoneSize }) => {
    switch ( phoneSize ) {
    case 'xs': return '660px';
    case 's': return '720px';
    default: return '820px';
    }
  }};
  background-color: #141414;
  
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;
const BigDesc = styled.div`
  /* margin-top: 80px; */
  margin-bottom: 40px;

  font-size: ${({ phoneSize }) => {
    switch ( phoneSize ) {
    case 'xs': return '25px';
    case 's': return '28px';
    default: return '30px';
    }
  }};
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: -0.94px;
  color: #4874f6;
`;
const AppIcon = styled.div`
  margin-bottom: 16px;
`;
const Title = styled.div`
font-size: 14px;

  .mobile-app-title {
    margin-bottom: 8px;

    font-weight: bold;
    line-height: 1.43;
    letter-spacing: -0.44px;
  }
  .mobile-app-desc {
    font-family: AppleSDGothicNeo;
    font-weight: 500;
    line-height: 1.71;
    letter-spacing: -0.88px;
  }
`;
const PhoneMockupImage = styled.div`
/* border: 1px solid red; */
  margin-bottom: -10px;
  position: relative;
`;
const MoreInfoWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 85px;

  width: 164px;
  height: 24px;
`;

function MobileApp({ lang, phoneSize }) {
  const getPhoneMockupWidth = React.useCallback( () => {
    switch ( phoneSize ) {
    case 'xs': return '250px';
    case 's': return '280px';
    default: return '360px';
    }
  }, [ phoneSize ]);

  const movePage = React.useCallback( ( path ) => {
    window.open( path );
  }, []);

  return (
    <Container name="mobileApp" phoneSize={phoneSize}>
      <OuterWrapper phoneSize={phoneSize}>
        <BigDesc phoneSize={phoneSize}>
          <span>{TEXTS.bidDesc}</span>
        </BigDesc>

        <AppIcon>
          <img src={appIcon} alt="app-icon" style={{ width: '72px' }} />
        </AppIcon>

        <Title>
          <div className="mobile-app-title"><span>{TEXTS.title}</span></div>
          <div className="mobile-app-desc"><span>{TEXTS.smallDesc[lang]}</span></div>
        </Title>

        <PhoneMockupImage>
          <img src={phonemockup} alt="phone-mock-up" style={{ width: getPhoneMockupWidth() }} />
          <MoreInfoWrapper>
            <MoreInfoBox onClick={() => movePage( address.mobileApp )} />
          </MoreInfoWrapper>
        </PhoneMockupImage>
      </OuterWrapper>
    </Container>
  );
}

export default MobileApp;
