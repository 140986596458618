/* eslint-disable no-underscore-dangle */
import React from 'react';
import styled from 'styled-components';
import { OuterWrapper } from './Main';
import { roadmapContents } from '../../meta/Roadmap';

const TITLE = 'Project Roadmap';

const Container = styled.section`
  width: 100%;
  /* height: 820px; */
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #141414;
`;
const ModifiedOuterWrapper = styled( OuterWrapper )`
  height: 100%; 
  display: flex;
  flex-direction: column; 
  justify-content: flex-start;
  padding-top: 40px;

`;
const TitleDiv = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: ${({ phoneSize, topBottomMargin }) => {
    switch ( phoneSize ) {
    case 'xs': return `${topBottomMargin + 25}px`;
    default: return `${topBottomMargin + 30}px`;
    }
  }};
  text-align: center;  
  font-size: ${({ phoneSize }) => {
    switch ( phoneSize ) {
    case 'xs': return '25px';
    default: return '30px';
    }
  }};
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: -0.94px;
  color: #4874f6;
`;

const RoadmapWrapper = styled.div`
  margin-top: 40px;
  height: ${({ height }) => `${height}px`};
  position: relative;
  .vertical-line {
    position: absolute;
    margin-left: 4.25px;
    top: 4px;
    height: 110%;
    border: 1px solid #4874f6;
  }
`;

function Roadmap({ lang, phoneSize }) {
  const roadmapContentDiv = React.useRef( null );
  const [ roadmapContentDivHeight, setRoadmapContentDivHeight ] = React.useState( 0 );
  const [ topBottomMargin, setTopBottomMargin ] = React.useState( 0 );

  React.useEffect( () => {
    const _contentDivHeight = roadmapContentDiv.current.offsetHeight;
    // phoneSize에 따른 값은 타이틀 사이즈
    // 40은 타이틀과 로드맵 컨텐츠 사이 공간
    const totalContentHeight = ( phoneSize !== 'xs' ? 30 : 25 ) + 40 + _contentDivHeight;

    setRoadmapContentDivHeight( _contentDivHeight );
    setTopBottomMargin( ( 800 - totalContentHeight ) / 2 );
  }, []);

  return (
    <Container name="roadmap">
      <ModifiedOuterWrapper
        phoneSize={phoneSize}
      >
        <TitleDiv phoneSize={phoneSize} topBottomMargin={topBottomMargin}><span>{TITLE}</span></TitleDiv>
        <RoadmapWrapper lang={lang} height={lang === 'ko' ? roadmapContentDivHeight + topBottomMargin : roadmapContentDivHeight + topBottomMargin + 40}>
          <div className="vertical-line" />
          <div ref={roadmapContentDiv}>
            {roadmapContents[lang].map( ( content, index ) => <RoadmapItem key={`content${index + 1}`} phoneSize={phoneSize} content={content} isLast={roadmapContents.ko.length === index + 1} /> )}
          </div>
        </RoadmapWrapper>
      </ModifiedOuterWrapper>
    </Container>
  );
}

const RoadmapItemContainer = styled.div`
  display: flex;
`;
const RoadmapCircleDiv = styled.div`
  margin-right: ${({ phoneSize }) => ( phoneSize === 'xs' ? '12px' : '21px' )};
  .circle {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: #4874f6;
    margin-top: 3px;
  }
`;
const RoadmapContent = styled.div`
  color: #969696;
`;
const RoadmapTitle = styled.div`
  margin-bottom: 16px;

  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  letter-spacing: -0.44px;
`;
const RoadmapText = styled.div`
  margin-bottom: ${({ isLast }) => ( !isLast ? '36px' : '0px' )};

  font-family: AppleSDGothicNeo;
  font-size: 10px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: -0.63px;
  li {
    display: table;
    list-style: none;
    word-break: keep-all;
  }
  li::before {
    content: "\u00B7";
    font-weight: 900;
    zoom: 1;
    display: table-cell;
    text-align: right;
    padding-right: 1em;
  }
`;
function RoadmapItem({ content, phoneSize, isLast }) {
  const { year, list } = content;
  return (
    <RoadmapItemContainer>
      <RoadmapCircleDiv phoneSize={phoneSize}>
        <div className="circle" />
      </RoadmapCircleDiv>
      <RoadmapContent>
        <RoadmapTitle><span>{year}</span></RoadmapTitle>
        <RoadmapText isLast={isLast}>
          {list.map( ( elem ) => (
            <li key={elem.id}>
              {elem.text}
            </li>
          ) )}
        </RoadmapText>
      </RoadmapContent>
    </RoadmapItemContainer>
  );
}

export default Roadmap;
