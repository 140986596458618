import React from 'react';
import styled from 'styled-components';

import Link from '../../meta/Header';
import { header } from '../common/images';

const {
  github, medium, twitter, telegram,
} = Link;

const Container = styled.header`
  min-width: 1250px;
  overflow: hidden;
`;

const Wrapper = styled.div`
  width: 1050px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
`;

const UpperBackground = styled.div`
  background-color: #1e1e1e;
`;

const UpperWrapper = styled( Wrapper )`
  height: 48px;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
`;

const List = styled.ul`
  display: flex;
`;

const Languages = styled( List )``;

const Socials = styled( List )`
  float: right;
`;

const Lauguage = styled.li`
  margin-right: 20px;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: -0.38px;
  font-family: GmarketSans;
  cursor: pointer;
`;

const Social = styled.li`
  margin-right: 20px;
  cursor: pointer;
  &:last-child {
    margin: 0;
  }
`;

function Header({ changeLanguage }) {
  const moveSocialLink = ( path ) => window.open( path );

  return (
    <Container>
      <UpperBackground>
        <UpperWrapper>
          <Languages>
            <Lauguage onClick={() => changeLanguage( 'ko' )}>KR</Lauguage>
            <Lauguage onClick={() => changeLanguage( 'en' )}>EN</Lauguage>
          </Languages>
          <Socials>
            <Social onClick={() => moveSocialLink( github )}>
              <Icon src={header.github} alt="github" />
            </Social>
            <Social onClick={() => moveSocialLink( medium )}>
              <Icon src={header.medium} alt="medium" />
            </Social>
            <Social onClick={() => moveSocialLink( twitter )}>
              <Icon src={header.twitter} alt="twitter" />
            </Social>
            <Social onClick={() => moveSocialLink( telegram )}>
              <Icon src={header.telegram} alt="telegram" />
            </Social>
          </Socials>
        </UpperWrapper>
      </UpperBackground>
    </Container>
  );
}

export default Header;
