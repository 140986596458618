import React from 'react';
import styled from 'styled-components';
import { main } from '../common/images';
import { OuterWrapper } from './Main';

import engWhitepaper from '../../assets/Whitepaper/apM-Coin_Whitepaper_Ver-4.0_Eng.pdf';
import korWhitepaper from '../../assets/Whitepaper/apM-Coin_Whitepaper_Ver-4.0_Kor.pdf';
import chiWhitepaper from '../../assets/Whitepaper/apM-Coin_Whitepaper_Ver-4.0_Chn.pdf';

const downloadLanguage = [
  {
    text: 'English',
    link: engWhitepaper,
  },
  {
    text: 'Korean',
    link: korWhitepaper,
  },
  {
    text: 'Chinese',
    link: chiWhitepaper,
  },
];

const { downloadIcon } = main;

const Container = styled.section`
  width: 100%;
  height: 152px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #4874f6;
`;
const ModifiedOuterWrapper = styled( OuterWrapper )`
  height: 69%;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;
const Title = styled.div`
  font-size: 14px;
  font-weight: bold;  
  line-height: 1.43;
  letter-spacing: -0.44px;
`;
const LanguageBoxWrapper = styled.div`
  height: 100%;
  width: 48%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const LanguageBox = styled.a`
  width: 100%;
  height: 24px;
  display: flex;
  align-items: center;
  
  .language {
    height: 100%;
    width: 100%;

    padding: 4px 8px;

    background-color: #000;
    font-size: 10px;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: -0.63px;
  }
  .download-box {
    display: flex;
    align-items: center;
    justify-content: center;
    
    background-color: #272f3a;
    width: 24px;
    height: 24px;
  }
`;

function Whitepaper({ phoneSize }) {
  return (
    <Container phoneSize={phoneSize}>
      <ModifiedOuterWrapper phoneSize={phoneSize}>
        <Title><span>Whitepaper</span></Title>

        <LanguageBoxWrapper>
          {downloadLanguage.map( ( language ) => (
            <LanguageBox key={language.text} href={language.link} target="_blank" rel="noreferrer">
              <div className="language"><span>{language.text}</span></div>
              <div className="download-box"><img src={downloadIcon} alt="download-icon" style={{ width: '18px' }} /></div>
            </LanguageBox>
          ) )}
        </LanguageBoxWrapper>
      </ModifiedOuterWrapper>
    </Container>
  );
}

export default Whitepaper;
