import React from 'react';

/* WEB */
import WebView from './Web';

/* MOBILE */
import MobileView from './Mobile';

/* TABLET */
import TabletView from './Tablet';

function DefaultPage({ device, lang, changeLanguage }) {
  const { isMobile, isTablet } = device;
  const [ phoneSize, setPhoneSize ] = React.useState( 'm' );
  const [ tabletSize, setTabletSize ] = React.useState( 'm' );

  React.useEffect( () => {
    if ( document.body.offsetWidth <= 350 ) {
      setPhoneSize( 's' );
    }
    if ( document.body.offsetWidth <= 290 ) {
      setPhoneSize( 'xs' );
    }
  }, []);

  React.useEffect( () => {
    if ( document.body.offsetWidth <= 770 ) {
      setTabletSize( 's' );
    }
  }, []);

  if ( isTablet ) {
    return <TabletView lang={lang} tabletSize={tabletSize} changeLanguage={changeLanguage} />;
  }

  if ( isMobile && !isTablet ) {
    return <MobileView lang={lang} phoneSize={phoneSize} changeLanguage={changeLanguage} />;
  }

  return <WebView lang={lang} changeLanguage={changeLanguage} />;
}

export default DefaultPage;
