import React from 'react';
import styled from 'styled-components';

import { mobileApp } from '../common/images';
import { mobileAppDescription, address } from '../../meta/MobileApp';
import { OuterWrapper } from './Main';

const bg = require( '../../assets/MobileApp/bg-web/img-bg-phonemockup-main/img-bg-phonemockup-main@3x.png' );

const Container = styled.section`
  width: 100%;
  height: 800px;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #141414;
`;
const ModifiedOuterWrapper = styled( OuterWrapper )`
  position: relative;
`;

const Title = styled.div`
  font-family: GmarketSans;
  font-size: 56px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -1.75px;
  .title-blue {
    color: #4874f6;
  }
`;

const MembersBackgroundImg = styled.div`
  width: 380px;
  height: 100%;
  background-image: url(${( props ) => props.bgImage});
  background-position: center bottom;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 0;
  position: absolute;
  right: 0;
  bottom: -120px;
`;

const MembersMockImage = styled.img`
  width: 72px;
  height: 72px;
  margin-top: 156px;
`;

const MembersIntroduce = styled.div`
    .name {
      width: 510px;
      height: 36px;
      margin: 24px 0 12px;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: -0.75px;
      color: #fff;
      font-family: GmarketSans;
    }
    .description {
      width: 450px;
      margin: 12px 48px 20px 0;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: -0.88px;
      color: #fff;
      word-break: ${( props ) => ( props.lang === 'ch' ? 'break-all' : 'keep-all' )};
    }
`;

const MembersMoreinfoContainer = styled.div`
  width: 462px;
  height: 32px;
  margin-top: 24px;
  display: flex;
  justify-content: flex-start;
  z-index: 1;
`;

const MoreinfoText = styled.div`
  width: 208px;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 0 0 16px;
  background-color: #4874f6;
  font-family: GmarketSans;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.88px;
  color: #fff;
  cursor: pointer;
`;

const MoreinfoButton = styled.button`
  width: 32px;
  height: 32px;
  padding: 4px;
  background-color: #000;
  cursor: pointer;
  border: none;
`;

const MoreinfoNextButtonImg = styled.img`
  width: 24px;
  height: 24px;
`;

function MobileApp({ lang, tabletSize }) {
  const movePage = React.useCallback( ( path ) => {
    window.open( path );
  }, []);

  return (
    <Container name="mobileApp">
      <ModifiedOuterWrapper tabletSize={tabletSize}>
        <MembersBackgroundImg bgImage={bg} />

        <Title>
          <div className="title-blue">Blockchain-Applied</div>
          <div className="title-blue">Mobile App Service for</div>
          <div className="title-blue">Wholesale Fashion Malls</div>
        </Title>

        <MembersMockImage src={mobileApp.appIcon} alt="app-icon" />
        <MembersIntroduce lang={lang}>
          <div className="name">apM MembersMobile App</div>
          <div className="description">{mobileAppDescription[lang]}</div>
        </MembersIntroduce>
        <MembersMoreinfoContainer>
          <MoreinfoText onClick={() => movePage( address.mobileApp )}>More info</MoreinfoText>
          <MoreinfoButton onClick={() => movePage( address.mobileApp )}>
            <MoreinfoNextButtonImg src={mobileApp.nextIcon} alt="next" />
          </MoreinfoButton>
        </MembersMoreinfoContainer>

      </ModifiedOuterWrapper>
    </Container>
  );
}

export default MobileApp;
