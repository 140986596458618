import React from 'react';
import styled from 'styled-components';

import { press } from '../common/images';
import { pressNews } from '../../meta/Press';

import { OuterWrapper } from './Main';

const Container = styled.section`
  width: 100%;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 850px;
  background-color: #19202a;
`;

const Wrapper = styled.div`
`;

const PressTitle = styled( Wrapper )`
  font-size: 56px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -1.75px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-family: GmarketSans;
  .title-white {
    color: #FFFFFF;
  }
  .sub {
    font-size: 20px;
    margin: 20px 0 0 20px;
  }
`;

const PressContainer = styled( Wrapper )`
`;

const PressContents = styled.div`
  margin-bottom: 15px;
  padding: 20px 30px;
  background-color: #000;
`;

const Newspaper = styled.div`
  width: 127px;
  height: 16px;
  margin: 0 831px 8px 0;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: -0.44px;
  color: #4874f6;
`;

const NewspaperContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NewspaperTitle = styled.div`
  width: 100%;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: -0.75px;
  text-decoration: underline;
  color: #fff;
  word-break: keep-all;
`;

const NewspaperButton = styled.button`
  width: 32px;
  height: 32px;
  padding: 4px;
  background-color: #4874f6;
  cursor: pointer;
  border: none;
`;

const NewspaperButtonIcon = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
`;

function Press({ lang, tabletSize }) {
  const moveLink = ( path ) => {
    window.open( path );
  };

  return (
    <Container>
      <OuterWrapper tabletSize={tabletSize}>
        <PressTitle>
          <div className="title-white">Press</div>
          <div className="title-white sub">(Korean)</div>
        </PressTitle>
        <PressContainer>
          {pressNews.map( ( news ) => (
            <PressContents key={`${news.id}-${lang}`}>
              <Newspaper>{news.newspaper}</Newspaper>
              <NewspaperContent>
                <NewspaperTitle onClick={() => moveLink( news.link )}>
                  {news.title}
                </NewspaperTitle>
                <NewspaperButton onClick={() => moveLink( news.link )}>
                  <NewspaperButtonIcon src={press.outlinkIcon} alt="outlink" />
                </NewspaperButton>
              </NewspaperContent>
            </PressContents>
          ) )}
        </PressContainer>
      </OuterWrapper>
    </Container>
  );
}

export default Press;
