/* eslint-disable no-nested-ternary */
import React from 'react';
import styled, { css } from 'styled-components';
import { about } from '../common/images';
import { assetsVoucher, assetsBridge, address } from '../../meta/About';
import { OuterWrapper } from './Main';

const {
  aboutBackground, aboutDiagram, evoucherIcon, blockchainIcon2, nextIcon,
} = about;

const TEXTS = {
  title: 'About\napM Coin',
  subTitle: 'Securely Managed on\napM Chain Platform',
  availableOn: 'Available on',
  evoucher: 'apM NFT Voucher',
  bridge: 'apM Bridge',
};

const items = [
  {
    name: TEXTS.evoucher,
    icon: evoucherIcon,
    desc: { ko: assetsVoucher.ko.replace( '상품권입니다. ', '상품권입니다.\n\n' ), en: assetsVoucher.en },
  },
  {
    name: TEXTS.bridge,
    icon: blockchainIcon2,
    desc: { ko: assetsBridge.ko, en: assetsBridge.en },
    isBridge: true,
  },
];

const Container = styled.section`
  width: 100%;
  height: ${({ phoneSize }) => {
    switch ( phoneSize ) {
    case 'xs': return '1040px';
    case 's': return '1120px';
    default: return '1180px';
    }
  }};

  padding: ${({ phoneSize }) => {
    switch ( phoneSize ) {
    case 'xs': return '50px 0';
    case 's': return '60px 0';
    default: return '80px 0';
    }
  }};
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  
  background-blend-mode: multiply;
  background-image: linear-gradient(to bottom, #969696, #969696), ${({ imageSrc }) => `url(${imageSrc})`};
  background-size: 100%;
  background-position: center top;
`;
const ModifiedOuterWrapper = styled( OuterWrapper )`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Title = styled.div`
  .title {
    margin-bottom: 16px;

    font-size: 30px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: -0.94px;
  }
  .sub-title {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    letter-spacing: -0.44px;
  }
`;
const DiagramDiv = styled.div`
  background-color: #141414;

  position: absolute;
  left: 0;
  top: ${({ phoneSize }) => {
    switch ( phoneSize ) {
    case 'xs': return '220px';
    case 's': return '240px';
    default: return '260px';
    }
  }};

  /* height: 560px; */
  height: 45%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .diagram-title {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    letter-spacing: -0.44px;

    margin-bottom: 25px;
  }
`;
const DiagramImage = styled.div`
  text-align: center;
  height: 75%;
`;
const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  /* height: ${({ lang }) => ( lang === 'ko' ? '260px' : '280px' )}; */
  min-height: 260px;
`;
const ItemBox = styled.div`
  width: 47%;
  position: relative;

  .item-name {
    height: 20px;
    margin-bottom: 8px;

    font-size: ${({ phoneSize }) => {
    switch ( phoneSize ) {
    case 'xs': return '10px';
    case 's': return '12px';
    default: return '14px';
    }
  }};
    font-weight: bold;
    line-height: 1.43;
    letter-spacing: -0.44px;
  }
  .item-desc {
    height: ${({ lang, phoneSize }) => ( lang === 'ko' ? '232px' : ( phoneSize !== 'xs' ? '248px' : '275px' ) )};
    padding: ${({ phoneSize }) => {
    switch ( phoneSize ) {
    case 'xs': return '10px 10px 0';
    default: return '16px 16px 0';
    }
  }};

    background-color: #1e1e1e;

    font-family: AppleSDGothicNeo;
    font-size: 10px;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: -0.63px;
  }
  .add-bottom-margin {
    margin-bottom: 16px;
  }
`;
const MoreInfoBoxWrapper = styled.div`
    ${({ lang }) => lang === 'en' && css`
    width: 80%;
    position: absolute;
    bottom: -10px;
    `
}
`;

function About({ phoneSize, lang }) {
  const movePage = React.useCallback( ( path ) => {
    window.open( path );
  }, []);

  return (
    <Container name="about" imageSrc={aboutBackground} phoneSize={phoneSize}>
      <ModifiedOuterWrapper phoneSize={phoneSize}>
        <Title>
          <div className="title"><span>{TEXTS.title}</span></div>
          <div className="sub-title"><span>{TEXTS.subTitle}</span></div>
        </Title>

        {/* <DiagramDiv phoneSize={phoneSize}>
          <div className="diagram-title"><span>{TEXTS.availableOn}</span></div>
          <DiagramImage><img src={aboutDiagram} alt="about-diagram" style={{ height: '100%' }} /></DiagramImage>
        </DiagramDiv> */}

        <ItemWrapper lang={lang}>
          {items.map( ( item ) => (
            <ItemBox key={item.name} phoneSize={phoneSize} lang={lang}>
              <div className="item-name"><span>{item.name}</span></div>
              <div className="item-desc">
                <div className="add-bottom-margin"><img src={item.icon} alt={item.name} style={{ width: '56px' }} /></div>
                <div>
                  <div className="add-bottom-margin"><span>{item.desc[lang]}</span></div>
                  {item.isBridge && (
                    <MoreInfoBoxWrapper lang={lang}>
                      <MoreInfoBox onClick={() => movePage( address.bridge )} />
                    </MoreInfoBoxWrapper>
                  )}
                </div>
              </div>
            </ItemBox>
          ) )}
        </ItemWrapper>
      </ModifiedOuterWrapper>
    </Container>
  );
}

const MoreInfoContainer = styled.div`
  width: 100%;
  height: 24px;
  
  font-family: GmarketSans;
  display: flex;
`;
const MoreInfoTextBox = styled.div`
  width: 80%;
  padding-left: 16px;
  
  display: flex;
  align-items: center;
  justify-content: flex-start;
  
  background-color: #4874f6;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: -0.63px;
`;
const NextIconWrapper = styled.div`
  width: 24px;
  background-color: #000;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export function MoreInfoBox({ onClick }) {
  return (
    <MoreInfoContainer onClick={onClick}>
      <MoreInfoTextBox><span>More Info</span></MoreInfoTextBox>
      <NextIconWrapper><img src={nextIcon} alt="next-icon" style={{ width: '18px' }} /></NextIconWrapper>
    </MoreInfoContainer>
  );
}

export default About;
