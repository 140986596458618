import React from 'react';
import styled from 'styled-components';
import { whitepaper } from '../common/images';
import { OuterWrapper } from './Main';

const engWhitepaper = require( '../../assets/Whitepaper/apM-Coin_Whitepaper_Ver-4.0_Eng.pdf' );
const korWhitepaper = require( '../../assets/Whitepaper/apM-Coin_Whitepaper_Ver-4.0_Kor.pdf' );
const chiWhitepaper = require( '../../assets/Whitepaper/apM-Coin_Whitepaper_Ver-4.0_Chn.pdf' );

const downloadLanguage = [
  {
    text: 'English',
    link: engWhitepaper,
  },
  {
    text: 'Korean',
    link: korWhitepaper,
  },
  {
    text: 'Chinese',
    link: chiWhitepaper,
  },
];

const Container = styled.div`
  width: 100%;
  height: 190px;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4874f6;
`;

const ModifiedOuterWrapper = styled( OuterWrapper )`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.75px;
  color: #fff;
  font-family: GmarketSans;
`;

const PaperList = styled.div`
  height: 135px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const Paper = styled.a`
  margin-right: 30px;
  width: 174px;
  display: flex;
  align-items: center;
  background-color: #000;
  cursor: pointer;
  .white-paper-title {
    width: 74px;
    height: 20px;
    margin: 6px 36px 6px 16px;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.88px;
    color: #fff;
    font-family: GmarketSans;
  }
  .white-paper-icon-background {
    width: 32px;
    height: 32px;
    margin: 0 0 0 36px;
    padding: 4px;
    background-color: #272f3a;
    border: none;
  }
  .white-paper-icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
`;

function Whitepaper({ tabletSize }) {
  return (
    <Container>
      <ModifiedOuterWrapper tabletSize={tabletSize}>
        <Title>Whitepaper</Title>
        <PaperList>
          {downloadLanguage.map( ( language ) => (
            <Paper key={language.text} href={language.link} target="_blank" rel="noreferrer">
              <div className="white-paper-title">{language.text}</div>
              <span className="white-paper-icon-background">
                <img className="white-paper-icon" src={whitepaper.whitepaperDownloadIcon} alt="icon" />
              </span>
            </Paper>
          ) )}
        </PaperList>
      </ModifiedOuterWrapper>
    </Container>
  );
}

export default Whitepaper;
