export const assetsVoucher = {
  ko: 'apM, apM PLACE, apM Luxe 에서 apM 멤버스 앱을 통해 활용 가능한 NFT 상품권입니다. 표준 규격을 준수하며, 타 플랫폼 혹은 블록체인 서비스와의 간편한 연계가 가능합니다.',
  en: 'apM NFT Voucher can be used in apM, apM PLACE, and apM Luxe through apM Members app. It complies with the standard and can be easily linked to other platforms or blockchain services.',
};

export const assetsBridge = {
  ko: 'apM 디지털 자산간의 이동을 위한 멀티체인 브릿지로, 현재 클레이튼과 이더리움 체인을 연결하고 있습니다.',
  en: 'apM bridge is a multi-chain bridge serivce for movement between apM Coin\'s digital assets, which currently supports Klaytn and Ethereum Chain connections.',
};

export const address = {
  bridge: 'https://bridge.apm-coin.com/',
};
